import UserHeader from "components/Headers/UserHeader";
import React,{useState} from "react";
// import useForm from './useForm';
// import validate from './validate';
import {
    CardHeader,
    Container,
    Card,
    Row, 
    Col,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    FormText,
} from 'reactstrap';
import {Link, useHistory} from "react-router-dom";
import './visitType.css';

const VisitSchedule = () => {
    // const {handleChange, values,handleSubmit, errors } = useForm(validate);
    let history = useHistory();

    const [option, setOption] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');

    const saveChange = e => {
        if(e.target.value == "tSession"){
            setOption(e.target.value);
        }
        else if(e.target.value == "rSession"){
            setOption(e.target.value);
        }
        
        
    }

    const buttonClick = (e) => {
        

        if(option == ''){
            document.getElementById("errorPrint").innerHTML = 'Please select an option to proceed';
        }
        if(fname == ""){
            document.getElementById("fnameError").innerHTML = 'Enter your first name';
        }
        if(lname == ""){
            document.getElementById("lnameError").innerHTML = 'Enter your last name';
        }

        if(email == ""){
            document.getElementById("emailError").innerHTML = 'Enter your email';
        }
        

        if(option == 'tSession'){
            history.push("therapySession");
        }
        else if(option=='rSession'){
            history.push("raterSession");
        }
    }

    // var card = document.getElementById("selectOption");
    // console.log(card.value);


    return(
        <>
        <UserHeader />
        {/* {Page Content} */}
        <Container className="mt--9 pb-4" fluid>
            <Row className="justify-content-center">
                <Col lg="6">
                    <Card className="p-2 shadow">
                        <div className="px-4 pt-4 text-center">
                            <h1 className="display-3 pb-1">MAPS Visit Schedule Request</h1>
                            
                            <hr  className="mt--1"/>
                        </div>
                        <CardBody className=" pb-1">
                            <Form id="form">
                            <Row className="mt--4 justify-content-center" >
                                <Col lg="9">
                                    <Label
                                        className="font-weight-bold"    
                                    >
                                    First name <span className="text-red">&nbsp;*</span>
                                    <p id="fnameError"></p>
                                    </Label>
                                    <FormGroup>
                                       
                                        <Input
                                           
                                            //   defaultValue="Lucky"
                                            // id="input-first-name"
                                            name="fname"
                                            placeholder="First name"
                                            type="text"
                                            bsSize="sm"
                                            // value= "fname"
                                            // value={values.fname}
                                            // onChange={handleChange} 
                                        />
                                        {/* {errors.fname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.fname}</p>} */}
                        
                                        {/* <FormText><span className="text-primary">First Name</span></FormText> */}
                                        {/* {errors.fname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.fname}</p>} */}
                        
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg="9">
                                    <Label
                                        className="font-weight-bold"    
                                    >
                                    Last name<span className="text-red">&nbsp;*</span>
                                    <p id="lnameError"></p>
                                    </Label>
                                    <FormGroup>
                                        {/* <Label
                                            className="font-weight-bold"
                                            htmlFor="input-last-name"
                                           
                                        >
                                        Last name<span className="text-red">&nbsp;*</span>
                                        </Label> */}
                                        <Input
                                            
                                            //   defaultValue="Jesse"
                                            // id="input-last-name"
                                            name="lname"
                                            placeholder="Last name"
                                            type="text"
                                            bsSize="sm"
                                            // value="lname"
                                            // value={values.lname}
                                            // onChange={handleChange} 
                                        />
                                       
                                        {/* {errors.lname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.lname}</p>}  */}

                                        {/* <FormText><span className="text-primary">Last Name</span></FormText> */}
                                        {/* {errors.lname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.lname}</p>}  */}

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg="9">
                                <FormGroup>
                                    <Label for="exampleEmail" className="font-weight-bold">
                                    Email <span className="text-red">&nbsp;*</span><p id="emailError"></p>
                                    </Label>
                                    <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="ex: myname@example.com"
                                    type="email"
                                    bsSize="sm"
                                    // value="email"
                                    // value="email"
                                    // onChange={handleChange}
                                    />
                                    
                                    <FormText>
                                    <span className="text-primary">
                                        example@example.com
                                    </span>
                                    </FormText>
                                    {/* {errors.email && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.email}</p>}  */}

                                </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center pb-2">
                                <Col lg="9">
                                    <FormGroup>
                                        <label className="font-weight-bold">
                                       Visit Type<span className="text-red">&nbsp;*</span>
                                       <p id="errorPrint"></p>
                                        </label><br />
                                        <Row>
                                        <Col>
                                       <select id="visitType" onChange={saveChange} bsSize="sm">
                                            
                                        <option>Please Select</option>
                                        <option value="tSession">Therapy Session</option>
                                        <option value="rSession">Independant Rater Session</option>

                                        </select>
                                        </Col>
                                        </Row>
                                        
                                     
                                        
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr  className="mt--1"/>
                            
                            <div className="text-end">
                            <Button color="primary" onClick={buttonClick}>Next</Button>
                            </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Container>

        </>
    );
};

export default VisitSchedule;