/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import valisLogo from './logosvg.svg';
import samLogo from './SAM.png';
import './Auth.css';
// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-secondary");
    return () => {
      document.body.classList.remove("bg-secondary");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div ref={mainContent} className="leftcol">
        {/* <AuthNavbar /> */}
        <div>
          <Container fluid >
          <Row>
          <Col className="bg-blue justify-content-start align-items-center pt-150" lg="8" md="7">
          <Row className="justify-content-center">
            <Col lg="4">
           <img src={valisLogo} alt="Valis" />
           </Col>
           </Row>
           <Row className="justify-content-center pt-100">
             <Col lg="4">
           <img src={samLogo} alt="SAM" id="samLogo"/>
           </Col>
           </Row>
       
          </Col>
        
        {/* <div className="header pt-6"> */}
          {/* <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-end">
                <Col lg="4" md="6">
                  <h1 className="text-white">SAMV</h1>
                  <p className="text-lead text-light">
                    Use any of these options to login or create new account in
                    your project.
                  </p>
                </Col>
              </Row>
            </div>
          </Container> */}
          {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        {/* </div> */}
        {/* Page content */}
        <Col className="pt-100 pb-150 " lg="4">
          
            <Switch>
              {getRoutes(routes)}
              <Redirect strict from="/" to="/auth/login" />
            </Switch>
      
          </Col>
          </Row>
        </Container>
      </div>
      </div>
      {/* <AuthFooter />   //Footer */}
    </>
  );
};

export default Auth;
