/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Session } from './views/basicComp/session';
import Login from "views/basicComp/Login.js";
import Profile from "views/basicComp/Profile";
// import EditTemplate from "./views/templates/edittemplate/editTemplate";


ReactDOM.render(
  <HashRouter>
    <Switch>
      <Session>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        {/* <Route path="/"><Login/></Route> */}
        {/* <Route path="/custom" render={(props) => <AdminLayout {...props} />} /> */}
        <Route exact path="/">
          <Redirect to="/auth/login" />
        </Route>
        {/* <Route path="/profile" element={<Profile />} /> */}
      </Session>
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
