import UserHeader from "components/Headers/UserHeader";
import React, { useState } from "react";
import {
    CardHeader,
    Container,
    Card,
    Row, 
    Col,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import {Link, useHistory} from "react-router-dom";
import './form.css';

const HardwareRequest = () => {
    let history = useHistory();

    // var a = false;
    // var b = false;

    // function validateForm() {
    //     var radios = document.getElementsByName("radio1");
    //     var formValid = false;
    
    //     var i = 0;
    //     while (!formValid && i < radios.length) {
    //         if (radios[i].checked) formValid = true;
    //         i++;        
    //     }
    
    //     if (!formValid) {
    //         document.getElementById('errorRadio').innerHTML = "Please select an option!";
      
    //     return formValid;}
    // };

//    const [values, setValues] = useState({});
//    const [a, setA] = useState(false);
//    const [b, setB] = useState(false);

//    const handleChange = e => {
//        e.preventDefault();
//        const {name, value} = e.target;
//        setValues({
//            [name]: value
//        })
//    }

//    if(values[0]=='hRequest'){
//         setA(true);
//    }

//    else if(values[1]=='hReturn'){
//        setB(true);
//    }


const [option, setOption] = useState('');

const handleChange = e => {
    if(e.target.checked && e.target.value == "hRequest"){
        setOption(e.target.value);
    }
    else if(e.target.checked && e.target.value == "hReturn"){
        setOption(e.target.value);
    }
    
}

const buttonClick = (e) => {
    e.preventDefault();

    if(option == ''){
        document.getElementById("errorPrint").innerHTML = 'Please select an option to proceed';
    }
    // console.log('......////',option);
    else if(option == 'hRequest'){
        history.push("hardwareRequest2");
    }
    else if(option=='hReturn'){
        history.push("hardwareReturn");
    }
}

// const handleClick = () => {
//     if(setOption=="hRequest"){
//         history.push("hardwareRequest2");
//     }
//     else if(setOption=="hReturn"){
//         history.push("hardwareReturn");
//     }
// }
    
    return(
        <>
        <UserHeader />
        {/* {Page Content} */}
        <Container className="mt--7 pb-4" fluid>
            <Row className="justify-content-center">
                <Col lg="7" >
                    <Card className="shadow">
                        <div className="px-4 pt-4">
                            <h1 className="display-3">Hardware Request or Return Form</h1>
                            <h3 className="text-primary"><span>ACAM Kit or other hardware shipping/delivery request form</span> </h3>
                            <hr />
                        </div>
                        <CardBody className="mt--5 pb-1">
                            <Form id="form">
                                <FormGroup tag="fieldset">
                                    <legend className="mt-3 font-weight-bold">
                                        Are you returning or requesting hardware?
                                    </legend>
                                    <p id="errorPrint" className="pb-10 text-danger"></p>

                                    <FormGroup check>                                      
                                    <Input
                                        name="hRequest"
                                        type="radio"

                                        value="hRequest"
                                        checked={option == "hRequest"}
                                        onChange={handleChange}
                                    />
                                    
                                    {' '}
                                    <label check>
                                      <span className="font-weight-normal">Hardware Request</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="hReturn"
                                        type="radio"
                                        value="hReturn"
                                        checked={option == "hReturn"}
                                        onChange={handleChange}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Hardware Return</span>
                                    </label>
                                    </FormGroup>
                                </FormGroup>
                               <hr />
                            
                            <div className="text-end mt-3 pb-3"  >  
                                
                                {/* <Link to="hardwareRequest2"> */}
                                <Button
                                color="primary"
                                size="md"
                                className="text-center"
                                type="submit"
                                id="btn"
                                onClick={buttonClick}
                                >
                                    Next
                                </Button>
                                {/* </Link> */}
                                
                               
                              
                            </div>
                            </Form>
                    </CardBody>     
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default HardwareRequest;