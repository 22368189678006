/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import './Sidebar.css';



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if(prop.view == "true"){
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              // onClick={closeCollapse}
              activeClassName="active"
            >
            
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }


//   function hide ()
//  {
  
//   document.getElementById("showNav").style.display = "none";
//  }

//  function show(){
//   document.getElementById("navbar-vertical").width = "0%";
//  }

let sideBar = document.querySelector("#sideBar");
let closeBtn = document.querySelector("#btn");
// let searchBtn = document.querySelector(".bx-search");

function doClick(){
  // sideBar.classList.toggle("open");
  if(document.getElementById('sideBar').style.width == '100%'){
    document.getElementById('sideBar').style.width="50px";
   
  }
  else{
  document.getElementById('sideBar').style.width="100%";
 
  
 
  // document.getElementById('btn2').style.display="block";
  }
  // menuBtnChange();//calling the function(optional)
};





  return (
    
    
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sideBar"  
     
    >
     
       {/* <button
          className="navbar-toggler d-xl-block"
          type="button"
          onClick={toggleCollapse}
         
        >
          <span className="navbar-toggler-icon" />
        </button> */}
      <Container fluid   >
        {/* Toggler */}
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="ml-5" {...navbarBrandProps}>
           
            <img  //Brand Logo
              alt={logo.imgAlt}
              className="navbar-brand-img "
              src={logo.imgSrc}
              // id="samvLogo" 
            />
             
            {/* <DropdownItem>
            <i class="fa-solid fa-bars"></i>
        </DropdownItem> */}
         
          </NavbarBrand>
         

          
        ) : null}
        {/* User */}

        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
         
        >
          <span className="navbar-toggler-icon" />
        </button>
        
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-1-800x800.jpg")
                        .default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar className="mt--4">
            <button
            // className="navbar-toggler d-xl-block"
            type="button"
            onClick={doClick}
            id="btn1" 
            > 
              <span className="navbar-toggler-icon" />
            </button>
            <div className="ml--2">
            {createLinks(routes)}
            

            {/* <hr className="text-light"/>
            <h2 className="text-center mt--4">Forms</h2>
            <i class="fa-solid fa-microchip"></i> Hardware Request */}
            </div>
            {/* <button
              
              type="button"
              onClick={againClick}
              id="btn2"
            > 
            <i class="fa-solid fa-circle-xmark"></i>
            </button> */}
          </Nav>
          {/* Divider */}

          {/* <button
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span className="navbar-toggler-icon" />
                  
                </button> */}
        
        </Collapse>
       
      </Container>
      
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
  // toggleSidenav: () => {},
  // sidenavOpen: false
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // toggleSidenav: PropTypes.func,
  // // prop to know if the sidenav is mini or normal
  // sidenavOpen: PropTypes.bool,
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
