import UserHeader from "components/Headers/UserHeader";
import React from "react";
import useForm from './useForm';
import validate from './validate';
import {
    CardHeader,
    Container,
    Card,
    Row, 
    Col,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    FormText,
} from 'reactstrap';
import {Link} from "react-router-dom";
import './visitType.css';


const TherapySession = () => {

    const {handleChange, values,handleSubmit, errors } = useForm(validate);

    function showFields(){
        document.getElementById('displayFields').style.display="block";
    }

    function hideFields(){
        document.getElementById('displayFields').style.display="none";
    }

    return(
        <>
        <UserHeader />
        {/* {Page Content} */}
        <Container className="mt--7 pb-4" fluid>
            <Row className="justify-content-center">
                <Col lg="8">
                    <Card className="p-3 shadow">
                        <CardBody className=" pb-1">
                            <Form onSubmit={handleSubmit}  id="form">
                            <Row className="justify-content-center">
                                <Col>
                                <FormGroup tag="fieldset">
                                    <legend className="mt-2 font-weight-bold">
                                       Select Study  <span className="text-red">&nbsp;*</span>
                                    </legend>
                                    {errors.radio && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.radio}</p>}

                                    <FormGroup check>
                                      
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    
                                    {' '}
                                    <label check>
                                      <span className="font-weight-normal ">MAPP2</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">MP18</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">MAPPUSX</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">MPLONG</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">MPVA6</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={showFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Other</span>
                                    </label>
                                    <Input type="text" 
                                           className="mt-2 ml--4"
                                           placeholder="Please type other option here.."
                                           id="displayFields" />
                                    </FormGroup>
                                </FormGroup>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col lg="6">
                                    <Label className="font-weight-bold">Participant ID <span className="text-red">&nbsp;*</span></Label>
                                    {errors.pID && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.pID}</p>}
                                    <Input 
                                        type="text" 
                                        name="pID" 
                                        value={values.pID}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                    />
                                   
                        
                                </Col>
                            </Row>
                            <br /><br />


                            <Row>
                                <Label className="font-weight-bold">Visit Start Time and Date (please enter the date and time in the local timezone of the participant's site).</Label>
                                <Col lg="4">
                                    <FormGroup>
                                    <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            bsSize="sm"
                                            // value={values.date}
                                            // onChange={handleChange}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <Input
                                        id="exampleTime"
                                        name="time"
                                        placeholder="time placeholder"
                                        type="time"
                                        bsSize="sm"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Col lg="5">
                                    <FormGroup>
                                        <label className="font-weight-bold">
                                            Visit Number <span className="text-red">&nbsp;*</span>
                                        </label>
                                        {errors.visitNumber && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.visitNumber}</p>}
                                        <Input
                                        id="visitNumber"
                                        name="visitNumber"
                                        type="select"
                                        value={values.visitNumber}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                        >
                                        <option>Select visit number</option>
                                        <option>V01</option>
                                        <option>V02</option>
                                        <option>V03</option>
                                        <option>V04</option>
                                        <option>V05</option>
                                        <option>V06</option>
                                        <option>V07</option>
                                        <option>V08</option>
                                        <option>V09</option>
                                        <option>V10</option>
                                        <option>V11</option>
                                        <option>V12</option>
                                        <option>V13</option>
                                        <option>V14</option>
                                        <option>V15</option>
                                        <option>V16</option>
                                        <option>V17</option>
                                        <option>V18</option>
                                        <option>V19</option>
                                        <option>V20</option>

                                        </Input>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Label className="font-weight-bold">Therapist 1 Name (required)</Label>
                                {errors.name &&  <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.name}</p>}
                                <Col>
                                    <Input 
                                        type="text" 
                                        name="fname" 
                                        value={values.fname}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                    />
                                    <FormText><span className="text-primary">First Name</span></FormText>
                                </Col>
                                <Col>
                                    <Input 
                                        type="text" 
                                        name="lname" 
                                        value={values.lname}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                    />
                                    <FormText><span className="text-primary">Last Name</span></FormText>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Label className="font-weight-bold">Therapist 1 Email <span className="text-red">&nbsp;*</span></Label>
                                {errors.email && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.email}</p>}
                        
                                <Col lg="6">
                                    <Input 
                                        type="email" 
                                        name="email" 
                                        value={values.email}
                                        onChange={handleChange}     
                                        bsSize="sm"
                                    />
                                    <FormText><span className="text-primary">example@example.com</span></FormText>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Label className="font-weight-bold">Therapist 2 Name (required)</Label>
                                {errors.name &&  <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.name}</p>}
                                <Col>
                                    <Input 
                                        type="text" 
                                        name="fname" 
                                        value={values.fname}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                    />
                                    <FormText><span className="text-primary">First Name</span></FormText>
                                </Col>
                                <Col>
                                    <Input 
                                        type="text" 
                                        name="lname" 
                                        value={values.lname}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                    />
                                    <FormText><span className="text-primary">Last Name</span></FormText>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Label className="font-weight-bold">Therapist 2 Email <span className="text-red">&nbsp;*</span></Label>
                                {errors.email && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.email}</p>}
                        
                                <Col lg="6">
                                    <Input 
                                        type="email" 
                                        name="email" 
                                        value={values.email}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                    />
                                    <FormText><span className="text-primary">example@example.com</span></FormText>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Col>
                                <FormGroup>
                                    <Label for="exampleText" className="font-weight-bold">
                                    Additional Notes (Optional)
                                    </Label>
                                    <Input
                                    id="exampleText"
                                    name="text"
                                    type="textarea"
                                    rows="4"
                                    />
                                </FormGroup>
                                </Col>
                              
                            </Row>
                           
                            <div className="text-center" >
                           
                               
                                   
                                        <Button
                                        color="danger"
                                        // size="md"
                                        >
                                            Back
                                        </Button>
                                    
                                
                                    {/* <Link to="/admin/hardwareRequest"> */}
                                        <Button
                                        color="primary"
                                        // size="md"
                                        type="submit"
                                      
                                        >
                                            Submit
                                        </Button>
                                    {/* </Link> */}
                               
                            </div>

                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Container>

        </>
    );
};

export default TherapySession;