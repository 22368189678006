/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState} from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import EditTemplate from "../views/templates/edittemplate/editTemplate";

import routes from "routes.js";
import './Admin.css';

const Admin = (props) => {
  // const [sidenavOpen, setsidenavOpen] = useState(true);
  // const toggleSidenav = () => {
  //   if (document.body.classList.contains("g-sidenav-pinned")) {
  //     document.body.classList.remove("g-sidenav-pinned");
  //     document.body.classList.add("g-sidenav-hidden");
  //   } else {
  //     document.body.classList.add("g-sidenav-pinned");
  //     document.body.classList.remove("g-sidenav-hidden");
  //   }
  //   setsidenavOpen(false);
  // };
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Template";
  };

  // if(Sidebar.sideBar.style.width== "100%"){
  //   document.getElementById("mainContent").style.marginLeft = "100px";  
  // }
  // else{
  //   document.getElementById("mainContent").style.marginLeft = "0px";  
  // }

  return (
    <>
      
      <Sidebar
      
        {...props}
        routes={routes}
        // toggleSidenav={toggleSidenav}
        // sidenavOpen={sidenavOpen}
        // logo={{
        //   innerLink: "/admin/index",
        //   imgSrc: require("../assets/img/brand/SAM.png").default,
        //   imgAlt: "...",
        // }}
        id="sideBar" 
      
      /> 
      
      <div className="main-content" ref={mainContent} id="mainContent" >
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          {/* <Redirect from="*" to="/admin/index" /> */}
        </Switch>
        
        <Container fluid>
          {/* <AdminFooter /> //Footer */}
        </Container>
      </div>
      {/* {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null} */}
    </>
  );
};

export default Admin;
