/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  DropdownItem,
  NavItem,
  TabContent,
  TabPane,
  CardTitle,
  NavLink,
  Table,
  CardText,
  Row,
  Col,
  Nav,
  Navbar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu

  
} from "reactstrap";
// import {
//   MDBTabs,
//   MDBTabsItem,
//   MDBTabsLink,
//   MDBTabsContent,
//   MDBTabsPane
// } from 'mdb-react-ui-kit';

import {Link} from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import './Profile.css';
// core components
import React, {useState} from 'react';
import UserHeader from "components/Headers/UserHeader.js";
import dpp from '../../assets/img/theme/dpp.jpg';

const Profile = () => {
  const [activeTab, setActiveTab] = useState('1');

  const [selectedImage, setSelectedImage] = useState(null);
  // const dpImg = document.querySelector('#dpImg');
  // const file = document.querySelector('#file');
  

  // file.addEventListener('change', function() {
  //   // const choosedFile = this.files[0];
  //   // if(choosedFile){
  //   //   const reader = new FileReader();
  //   //   reader.addEventListener('load', function(){
  //   //     dpImg.setAttribute('src', reader.result);
  //   //   });
  //   //   reader.readAsDataURL(choosedFile);
  //   // }
  // });

  const onImageChange = (e) => {
    if(e.target.files && e.target.files[0]){
      let img = e.target.files[0];
      setSelectedImage(URL.createObjectURL(img));
    }
  }


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          
        <Col className="order-xl-2 mb-0 mb-xl-0" xl="12">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                  
                    <div className="contain">
                   
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={
                          require("../../assets/img/theme/dpp.jpg")
                          .default
                        }
                        accept= "image/*"
                        id="dpImg"
                      />
                    
                      </a>
                 
                    {/* <Link to="/admin/editProfile"> */}
                    <div className="onImage">
                       
                          <input type="file" id="file"  
          //                 onChange={(e)=> {
          // setSelectedImage(e.target.files[0]);}}
          onChange={onImageChange}
          >

          </input>
                          <label for="file" id="uploadBtn" 
                         >
                            <i class="fa-solid fa-upload"></i>&nbsp;Choose Image
                          </label>
                       
                      </div>
                      {/* </Link> */}
                      </div>
                  </div>
                  
                  <div className="text-center pt-150 ">
                  <h3>
                   Fahad Aftab
                    {/* <span className="font-weight-light">, 27</span> */}
                    {/* <Link to="/admin/userInfo">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-regular fa-pen-to-square"></i></span>
                    </Link> */}
                  </h3>
                  
                  
                 
                  </div>
                </Col>
              </Row>
              {/* <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"> */}
                {/* <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div> */}
              {/* </CardHeader> */}
              <CardBody className=" text-center">
               
                  {/* <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div>
                    </div>
                  </div> */}
                
                  
                {/* <Button  className="ml-100">
                 
                <Link to="/admin/education" >
                  <span>Education</span>
                  </Link>
                </Button>
              
               
                <Button >
                 
                <Link to="/admin/bio">
                  <span>Bio</span>
                  </Link> 
                </Button>
                
               
                <Button >
                 
                <Link to="/admin/position">
                  <span>Position</span>
                  </Link>
                </Button> */}
              <div>
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink  id="n1" className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                    BIO
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink id="n1" className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                   ADDRESS
                  </NavLink>
                  
                </NavItem>
                <NavItem>
                  <NavLink id="n1" className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                   EDUCATION
                  </NavLink>
                  
                </NavItem>
                <NavItem>
                  <NavLink id="n1" className={activeTab == '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                   LOGS
                  </NavLink>
                  
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} >
                <TabPane tabId="1" className=" justify-content-center ">
                <Form >
                    
                    <div className="pl-lg-4">
                      <Row className="justify-content-center">
                        
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                            //   defaultValue="Lucky"
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                            //   defaultValue="Jesse"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="jesse@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col lg="6">
                        <FormGroup>
                          <label for="exampleSelect" className="form-control-label"
                            htmlFor="input-country">
                           Date of Birth
                          </label>
                          
                          <Row >
                            <Col >
                            <Input  id="infoDate"
                                  name="select"
                                  type="select"
                          >
                          <option>Date</option>
                          <option value="01">1</option>
          <option value="02">2</option>
          <option value="03">3</option>
          <option value="04">4</option>
          <option value="05">5</option>
          <option value="06">6</option>
          <option value="07">7</option>
          <option value="08">8</option>
          <option value="09">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
          <option value="26">26</option>
          <option value="27">27</option>
          <option value="28">28</option>
          <option value="29">29</option>
          <option value="30">30</option>
          <option value="31">31</option>
                          </Input>
                            </Col>
                            
                            <Col>
                            <Input id="infoMonth"
                                  name="select"
                                  type="select"
                          >
                          <option>Month</option>
                          <option value="01">January</option>
        <option value="02">February</option>
        <option value="03">March</option>
        <option value="04">April</option>
        <option value="05">May</option>
        <option value="06">June</option>
        <option value="07">July</option>
        <option value="08">August</option>
        <option value="09">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
                          </Input>
                            </Col>
                            <Col>
                            <Input  id="infoYear"
                                  name="select"
                                  type="select"
                          >
                          <option>Year</option>
                          <option value="2030">2030</option>
          <option value="2029">2029</option>
          <option value="2028">2028</option>
          <option value="2027">2027</option>
          <option value="2026">2026</option>
          <option value="2025">2025</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
          <option value="2014">2014</option>
          <option value="2013">2013</option>
          <option value="2012">2012</option>
          <option value="2011">2011</option>
          <option value="2010">2010</option>
          <option value="2009">2009</option>
          <option value="2008">2008</option>
          <option value="2007">2007</option>
          <option value="2006">2006</option>
          <option value="2005">2005</option>
          <option value="2004">2004</option>
          <option value="2003">2003</option>
          <option value="2002">2002</option>
          <option value="2001">2001</option>
          <option value="2000">2000</option>
          <option value="1999">1999</option>
          <option value="1998">1998</option>
          <option value="1997">1997</option>
          <option value="1996">1996</option>
          <option value="1995">1995</option>
          <option value="1994">1994</option>
          <option value="1993">1993</option>
          <option value="1992">1992</option>
          <option value="1991">1991</option>
          <option value="1990">1990</option>
          <option value="1989">1989</option>
          <option value="1988">1988</option>
          <option value="1987">1987</option>
          <option value="1986">1986</option>
          <option value="1985">1985</option>
          <option value="1984">1984</option>
          <option value="1983">1983</option>
          <option value="1982">1982</option>
          <option value="1981">1981</option>
          <option value="1980">1980</option>
          <option value="1979">1979</option>
          <option value="1978">1978</option>
          <option value="1977">1977</option>
          <option value="1976">1976</option>
          <option value="1975">1975</option>
          <option value="1974">1974</option>
          <option value="1973">1973</option>
          <option value="1972">1972</option>
          <option value="1971">1971</option>
          <option value="1970">1970</option>
          <option value="1969">1969</option>
          <option value="1968">1968</option>
          <option value="1967">1967</option>
          <option value="1966">1966</option>
          <option value="1965">1965</option>
          <option value="1964">1964</option>
          <option value="1963">1963</option>
          <option value="1962">1962</option>
          <option value="1961">1961</option>
          <option value="1960">1960</option>
          <option value="1959">1959</option>
          <option value="1958">1958</option>
          <option value="1957">1957</option>
          <option value="1956">1956</option>
          <option value="1955">1955</option>
          <option value="1954">1954</option>
          <option value="1953">1953</option>
          <option value="1952">1952</option>
          <option value="1951">1951</option>
          <option value="1950">1950</option>
          <option value="1949">1949</option>
          <option value="1948">1948</option>
          <option value="1947">1947</option>
          <option value="1946">1946</option>
          <option value="1945">1945</option>
          <option value="1944">1944</option>
          <option value="1943">1943</option>
          <option value="1942">1942</option>
          <option value="1941">1941</option>
          <option value="1940">1940</option>
          <option value="1939">1939</option>
          <option value="1938">1938</option>
          <option value="1937">1937</option>
          <option value="1936">1936</option>
          <option value="1935">1935</option>
          <option value="1934">1934</option>
          <option value="1933">1933</option>
          <option value="1932">1932</option>
          <option value="1931">1931</option>
          <option value="1930">1930</option>  
                        </Input>
                            </Col>
                          </Row>
                          
                           </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Row className="justify-content-center">
                    <Col lg="6">
                    <div className="pl-lg-4">
                    <FormGroup>
                      <label  className="form-control-label">Tell us about yourself...</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="............ "
                        rows="4"
                      //   defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                      //   Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                  </Col>
                  </Row>
                    {/* Description */}
                    
                    <div className="text-center">
                    <hr className="text-light" />
                    <Link to="/admin/profile">
                    <Button
                        color="primary"
                        // size="md"
                        className="text-center"
                    >
                    
                        Save 
                        {/* <i class="fas fa-save"></i>    */}
                    </Button>
                    </Link> &nbsp;
                    <Link to="/admin/profile">
                    <Button
                        color="danger"
                        // size="md"
                        className="text-center"
                    >
                    
                        Cancel 
                        {/* <i class="fas fa-save"></i>    */}
                    </Button>
                    </Link>
                    </div>
                  </Form>
                
                </TabPane>
                <TabPane tabId="2" className="mt-5"> 
                    <i className="ni location_pin" />
                    Bucharest, Romania
                    <div className="text-center mt-5">  
                    <hr className="text-light"/> 
                    <Link to="/admin/address">
                    <Button
                     color="primary"
                     // size="md"
                     className="text-center"
                    >
                      <i class="fa-solid fa-pencil"></i>
                     &nbsp;Edit 
                     {/* <i class="fas fa-save"></i>    */}
                    </Button>
                    </Link>
                 </div>
                  </TabPane>
                  <TabPane tabId="3" className="mt-5"> 
                  <div className="font-weight-700">Harvard University,</div>
                  BS Computer Science,<br />
                  2017-2021
                  <div className="text-center mt-5">  
                    <hr className="text-light"/> 
                    <Link to="/admin/education">
                    <Button
                     color="primary"
                     // size="md"
                     className="text-center"
                    >
                      <i class="fa-solid fa-pencil"></i>
                     &nbsp;Edit 
                     {/* <i class="fas fa-save"></i>    */}
                    </Button>
                    </Link>
                 </div>
                  </TabPane>
                  <TabPane tabId="4"> 
                  
                   
                        <Table className=" table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Action</th>
                              <th scope="col">Message</th>
                              <th scope="col">Date</th>
                              {/* <th scope="col">Users</th> */}
                              {/* <th scope="col">Completion</th> */}
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>John Doe</td>
                              <td>Login</td>
                              <td>Error in logging in.</td>
                              <td>28-MAR-22</td>
                              <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            // onClick={() => deleteTemplate(val.Username)}
                                          >
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                            </tr>
                            <tr>
                              <td>John Doe</td>
                              <td>Login</td>
                              <td>Logged in successfully.</td>
                              <td>28-MAR-22</td>
                              <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            // onClick={() => deleteTemplate(val.Username)}
                                          >
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                            </tr>
                            <tr>
                              <td>John Doe</td>
                              <td>Logout</td>
                              <td>Logged out successfully.</td>
                              <td>28-MAR-22</td>
                              <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            // onClick={() => deleteTemplate(val.Username)}
                                          >
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                            </tr>
                          </tbody>
                        </Table>
                    
                  </TabPane>
              </TabContent>
            </div>
            {/* <div className="nav-tabs">
             
                <NavItem>1</NavItem>
                <NavItem>2</NavItem>
            
            </div>
                 */}
                {/* <MDBTabs className='mb-5 justify-content-center '>
        <MDBTabsItem id="mdb1">
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
           BIO
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem id="mdb2" className="pl-1">
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
           ADDRESS
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem id="mdb3"  className="pl-1">
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
           EDUCATION
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent >
        <MDBTabsPane show={basicActive === 'tab1'}>
        Ryan — the name taken by Melbourne-raised, Brooklyn-based
                    Nick Murphy — writes, performs and records all of his own
                    music.  
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab2'}>
                    <i className="ni location_pin" />
                    Bucharest, Romania
                  </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab3'}> University of Computer Science</MDBTabsPane>
      </MDBTabsContent>
                   */}
           
                
                  {/* <Nav className="navs justify-content-center" >
                    <li onClick={show}>
                    <Link
                      className="h4 mb-0 text-black text-uppercase"
                     
                    >
                  EDUCATION
                    </Link>
                    </li>
                    <li>
                    <Link
                      className="h4 mb-0 text-black text-uppercase"
                      to="/"
                    >
                   BIO
                    </Link>
                    </li>
                    <li>
                    <Link
                      className="h4 mb-0 text-black text-uppercase"
                      to="/"
                    >
                   POSITION
                    </Link>
                   </li>
                 
                  </Nav> */}
                 
                
               
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </Container>
    </>
  );
};

export default Profile;



































 