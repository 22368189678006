import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {SessionContext} from '../../basicComp/session';
import Swal from 'sweetalert2'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Container,
    Row,
    Alert,
    Col,
  } from "reactstrap";
// import UserHeader from "../../../components/Headers/UserHeader.js";
import Header from "../../../components/Headers/Header.js";

function ImageUpload(props) {


    const history = useHistory();
    const { getSession } = useContext(SessionContext); //For Session check
    const [errors, setErrors] = useState('');
    const [preSignUrl, setPreSignUrl] = useState('');
    const [image, setImage] = useState(null);
    const [imageShow, setImageShow] = useState(null);


    useEffect(() => {
        checkSession();
      },[]);

    
      const checkSession = () => {
        getSession()
        .then(session => {
          console.log('Session:EDIT', session);
          // setStatus(true);
        })
        .catch(err => {
          history.push(`/auth/login`)
        })
      }

    const getPreSignedUrl = (url, type) => {
        fetch(`https://api.samv.valisr.com/getSignedUrl/?url=${url}&type=${type}`,{
          method: "GET",
        })
            .then(response => response.json())
            .then(data =>
                {
                    let result = data.data;
                    setPreSignUrl(result);
                }
            )
            .catch((error)=>{
              setImage(null)
              setImageShow(null)
              console.log("Error......",error);
           });
    }

    const upload = async(e) => {
        e.preventDefault();
        if(image !== null){
            await fetch(preSignUrl, {
                method: "PUT",
                headers: {
                    'Content-Type': 'image/png',
                    // 'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'image/*'
                    // 'x-amz-acl': 'authenticated-read'
                  },
                  body: image
               })
               .then(function(res) {
                    console.log("Success......",res);
                    if(res.status === 200){
                      Swal.fire('Success','Image uploaded successfully!','success')
                      setImage(null)
                      setImageShow(null) 
                    }else{
                      Swal.fire('Error','Image not uploaded!','error')
                      setImage(null)
                      setImageShow(null)
                    }
             })
             .catch((error)=>{
                setImage(null)
                setImageShow(null)
                Swal.fire('Error','Something went wrong!','error')
                console.log("Error...sssss...",error);
             });
        }else{
            setErrors("You have not choose any image.")
            return
        }
    }

    const onImageChange = (e) => {
        
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setErrors("");
            setImageShow(URL.createObjectURL(img))
            setImage(img)
            getPreSignedUrl(img.name, img.type);
        }
    }


    
    const cancel = (e) =>{
        e.preventDefault();
        // setErrors('');
        history.push(`/admin`)
    }

    return (
        <>
        <Header />
      <Container className="mt--9" fluid>
        <Row>
          
          <Col className="order-xl-1" xl="9">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h3 className="mb-0">S3 Image Upload</h3>
                  </Col>
                  <Col className="text-right" xs="1">
                    <Button
                      color="danger"
                    onClick={(e) => cancel(e)}
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="text-right" xs="1">
                    <Button
                      color="primary"
                      onClick={e => upload(e)}
                      size="sm"
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {Object.keys(errors).length > 0 && errors ?
                <Alert className="alert-danger">
                    <strong>{errors}</strong>
                </Alert>
                :
                ""
              }
              <CardBody>
              <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Image Upload
                  </h6>
                  <div className="pl-lg-4">
                  <Row>
                      <Col lg="6">
                        <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Select Image
                          </label>
                          <input type="file" accept="image/png" name="myImage" onChange={e => onImageChange(e)}/>
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                        <FormGroup>
                          <img style={{"width":"150px"}} src={imageShow} alt="alternate.png"/>
                          </FormGroup>
                          </Col>
                            </Row>
                    </div>
                </Form>
              </CardBody>
              </Card>
              </Col>
              </Row>
              </Container>
        </>
    )
}

export default ImageUpload;

