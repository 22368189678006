import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {SessionContext} from '../../basicComp/session';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Alert,
    Col,
    UncontrolledTooltip 
  } from "reactstrap";
// import UserHeader from "../../../components/Headers/UserHeader.js";
import Header from "components/Headers/Header.js";
// import { CKEditor } from 'ckeditor4-react';
import Swal from 'sweetalert2'
import CKEditor from "react-ckeditor-component";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

function EditTemplate(props) {
    
    const history = useHistory();
    const { getSession } = useContext(SessionContext); //For Session check
    const [isloading, setIsLoading] = useState(false);
    const [createCheck, setCreateCheck] = useState(false);
    const [errors, setErrors] = useState('');
    const [s3images, setS3Images] = useState([]);
    const [html, sethtml] = useState('');
    const [textpart, settextpart] = useState('');
    const [subject, setsubject] = useState('');
    const [template, settemplate] = useState('');
    // const [preSignUrl, setPreSignUrl] = useState('');
    // const [imageUrl, setImageUrl] = useState('');
    // const [addedData, showData] = useState(0);
    const { temp } = useParams();

    
    useEffect(() => {
      if(temp !== 'create'){
        getTemplate(temp);
        getImagesList();
        checkSession();
      }else{
        setCreateCheck(true);
        checkSession();
      }
      },[]);

      const checkSession = () => {
        getSession()
        .then(session => {
          // console.log('Session:EDIT', session);
          // setStatus(true);
        })
        .catch(err => {
          history.push(`/auth/login`)
        })
      }
    
      const onChangee = (evt) => {
        var newContent = evt.editor.getData();
        sethtml(newContent);
      }
      
      const onBlur = (evt) => {
        console.log("onBlur event called with event info: ", evt);
      }
      
      const afterPaste = (evt) => {
        console.log("afterPaste event called with event info: ", evt);
      }
      
      const copytoclipboard = (e, imageurl) => {
        e.preventDefault();
        navigator.clipboard.writeText(imageurl)
      }

      const getTemplate = (tempName) => {
          setIsLoading(true);
          fetch(`https://api.samv.valisr.com/get-single-template/?tempName=${tempName}`,{
            method: 'GET',
        })
              .then(response => response.json())
              .then(data =>
                  {
                    // console.log("dataaaa....",data);
                      let result = data.Template
                          setIsLoading(false)
                          sethtml(result.HtmlPart)
                          settextpart(result.TextPart)
                          setsubject(result.SubjectPart)
                          settemplate(result.TemplateName)
                  })
              .catch((error) => {
                    setIsLoading(false);
                    Swal.fire('Error','Something went wrong!','error')
                    // console.error('Errors', error);
              })
      }

      const getImagesList = () => {

          fetch(`https://api.samv.valisr.com/listS3Objects`,{
            method: 'GET',
            // headers: {
            //   'Content-Type': 'application/json',
            // }
        })
          .then(response => response.json())
          .then(data =>{
            let result = data;
            setS3Images(result)
          }) 
          .catch((error) => {
            Swal.fire('Error','Something went wrong!','error')
            console.error('Errors', error);
          });

      }
    
      let submitButton = (e) => {
          
        e.preventDefault();

          if(template == ""){
              setErrors('Template name is required');
          }else{
              setErrors("");
          let data = {
              "templateName": template,
              "htmlPart": html,
              "subjectPart": subject,
              "textPart": textpart
          }
          var url;
          if(createCheck){
            url = `https://api.samv.valisr.com/createTemplate`;
          }else{
            url = `https://api.samv.valisr.com/editTemplate`;
          }
          fetch(url,{
              method: createCheck ? 'POST': 'PUT',
              // headers: {
              //   'Content-Type': 'application/json',
              // },
              body: JSON.stringify(data),
          })
              .then(response => response.json())
              .then(data =>
                  {
                      Swal.fire('Success', createCheck?'Template created successfully':'Template updated successfully', 'success')
                      history.push(`/admin/list/templates`)
                  }
              )
              .catch((error) => {
                  Swal.fire('Error','Something went wrong!','error')
                  console.error('Error:', error);
                });
              }
      }

    // const imageChange = (e) => {  dropdown image listing no need
    //     e.preventDefault();
    //     let key = e.target.value;
    //     setImageUrl(key);
    // }

      const cancel = (e) => {
          e.preventDefault();
          setErrors('');
          history.push(`/admin/list/templates`)
      }


    return (
      <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>   
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h3 className="mb-0">{createCheck ? 'Create Template' : 'Edit Template'}</h3>
                  </Col>
                  <Col className="text-right" xs="1">
                    <Button
                      color="danger"
                    onClick={(e) => cancel(e)}
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="text-right" xs="1">
                    <Button
                      color="primary"
                      onClick={e => submitButton(e)}
                      size="sm"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {Object.keys(errors).length > 0 && errors ?
                <Alert className="alert-danger">
                    <strong>{errors}</strong>
                </Alert>
                :
                ""
              }
              <CardBody>
              {isloading ? <ClipLoader
								css={override}
								sizeUnit={"px"}
								size={50}
								color={'#123abc'}
								loading={isloading}
							/> :
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Template
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Template Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="template-name"
                            placeholder="Template Name"
                            type="text"
                            name="template"
                            value={template}
                            onChange={e => settemplate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Subject
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="subject"
                            placeholder="Subject"
                            type="text"
                            name="subject"
                            value={subject}
                            onChange={e => setsubject(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  
                  {createCheck ? 
                  ""
                  :
                  <div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">S3 Images</h6>
                  <div className="pl-lg-4">
                  <Row>
                  {s3images.map((image, index) =>
                    <Col md="4" key={index}>
                    <FormGroup>
                            <img src={image}  style={{"width":"80px"}}/>
                            <div className="caption">
                              <Button className="btn-white" color="default" data-placement="top" id="tooltip611234743" size="sm" type="button" onClick={e => copytoclipboard(e, image)}>Copy</Button>
                              <UncontrolledTooltip delay={0} placement="top" target="tooltip611234743">Copy To Clipboard</UncontrolledTooltip>
                            </div>
                    </FormGroup>
                    </Col>
                      )}
                  </Row>
                  </div>
                  </div>
}
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Body</h6>
                  <div className="pl-lg-4">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                            <label>HTML</label>
                            {/* <Input
                                className="form-control-alternative"
                                placeholder="<p>Reset your password using this link. This link will be expired in 48 hours.</p>."
                                rows="4"
                                type="textarea"
                                name="html"
                                value={html}
                                onChange={e => sethtml(e.target.value)}
                            /> */}
                            <CKEditor 
                              content={html} 
                              events={{
                                "blur": e =>onBlur(e),
                                "afterPaste": e => afterPaste(e),
                                "change": e => onChangee(e),
                              }}
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                            <FormGroup>
                                <label>Text</label>
                                <Input
                                    className="form-control-alternative"
                                    placeholder="A few words about you ..."
                                    rows="4"
                                    type="textarea"
                                    name="textpart"
                                    value={textpart}
                                    onChange={e => settextpart(e.target.value)}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                  </div>
                </Form>
            }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default EditTemplate;