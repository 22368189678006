import React, { useState, useEffect, useContext } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Button,
    Table,
    Container,
    Row,
  } from "reactstrap";
import { useHistory } from 'react-router-dom'
import {SessionContext} from '../../basicComp/session';
  // core components
import Swal from 'sweetalert2'
import Header from "components/Headers/Header.js";
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment';
import { css } from '@emotion/react';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

const style = {
  height: 400,
  margin: 6,
  padding: 8
};


    function ListTemplates() {

        const history = useHistory();
        const { getSession } = useContext(SessionContext); //For Session check
        const [moreList, setMoreList] = useState(false);
        const [token, setToken] = useState("false");
        const [emailTemp, setEmailTemp] = useState([]);
        const [isloading, setIsLoading] = useState(true);
      
        useEffect(() => {
            getEmailTemplates(true);
            checkSession();
        },[]);

        const checkSession = () => {
          getSession()
          .then(session => {
            // console.log('Session:', session);
            // setStatus(true);
          })
          .catch(err => {
            history.push(`/auth/login`)
          })
        }

        const getEmailTemplates = (check) => {
            setIsLoading(true);
            setMoreList(true);
            let authToken = JSON.parse(localStorage.getItem('Authorization'));
            console.log("///////check...",check)
            // console.log("///////token...Type.....",typeof(token))
            fetch(`https://api.samv.valisr.com/listTemplates/?token=${check?"false":token}`,{
            method: "GET",
            headers: {
              Authorization: authToken
            }
          })
                .then(response => response.json())
                .then(data =>
                    {
                    if(Object.keys(data).length !== 0){
                        let root = data;
                        let result = root.TemplatesMetadata
                        if(root && root.NextToken){
                          console.log("root.NextToken.......",root.NextToken);
                          console.log("root.NextToken...TypeOf....",typeof(root.NextToken));
                          setToken(root.NextToken)
                        }else{
                          setMoreList(false);
                        }
                        setEmailTemp(result)
                        setIsLoading(false);
                    }
                    }
                )
                .catch((error) => {
                  setIsLoading(false);
                  Swal.fire('Error','Something went wrong!','error')
            })
        }

        const editTemplate = (tempName) => {
          history.push(`/admin/template/${tempName}`)
        }

        const createTemplate = () => {
          history.push(`/admin/template/create`)
        }

        const cloneTemplate = (tempName) => {
          setIsLoading(true)
          fetch(`https://api.samv.valisr.com/cloneTemplate/?tempName=${tempName}`,{
            method: 'GET',
          })
              .then(response => response.json())
              .then(data =>
                  {
                      console.log("data......",data.message);
                      Swal.fire('Success','Template clone made successfully!','success')
                      getEmailTemplates(true)
                  })
              .catch((error) => {
                setIsLoading(false)
                console.error('Errors', error);
                Swal.fire('Error','Something went wrong!','error')
                   
              })
        }

        const deleteTemplate = (tempName) => {
          setIsLoading(true);
          fetch(` https://api.samv.valisr.com/deleteTemplate/?tempName=${tempName}`,{
            method: 'GET',
            // headers: {
            //   'Content-Type': 'application/json',
            // }
          })
              .then(response => response.json())
              .then(data =>
                  {
                      console.log("data......",data);
                      Swal.fire('Success','Template deleted successfully!','success')
                      getEmailTemplates(true)
                  })
              .catch((error) => {
                setIsLoading(false);
                Swal.fire('Error','Something went wrong!','error')
                console.error('Errors', error);
              })
        }

        const sendEmail = (tempName) => {
          history.push(`/admin/sendmail/${tempName}`)
        }

        return (
            <>
              <Header />
              {/* Page content */}
              <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                  <div className="col">
                    <Card className="shadow">
                      <CardHeader className="border-0">
                        <h3 className="mb-0">Email Templates</h3>
                      </CardHeader>
                      <Col className="col-auto">
                        <Button
                          color="primary"
                          onClick={() => createTemplate()}
                          size="sm"
                        >
                          Create Template
                        </Button>
                      </Col>
                      <InfiniteScroll
                      dataLength={token !== 'false' ? 100 : 0}
                      next={getEmailTemplates}
                      hasMore={moreList}
                      loader={""}
                    >
                      <div style={style}>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">###</th>
                              <th scope="col">Template Name</th>
                              <th scope="col">Created Date</th>
                              {/* <th scope="col">Users</th> */}
                              {/* <th scope="col">Completion</th> */}
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                          {
                              emailTemp.length > 0 ?
                              emailTemp.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <th scope="row">
                                      {key + 1}
                                    </th>
                                    <td>{val.Name}</td>
                                    <td>
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        {moment(val.CreatedTimestamp).format("YYYY/MM/DD") || ""}
                                      </Badge>
                                    </td>
                                    <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            onClick={() => editTemplate(val.Name)}
                                          >
                                            Edit
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => cloneTemplate(val.Name)}
                                          >
                                            Clone
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => deleteTemplate(val.Name)}
                                          >
                                            Delete
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => sendEmail(val.Name)}
                                          >
                                            Send Email
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                )
                            
                              })
                              : 
                              <tr >
                                <td colSpan="4" style={{ textAlign: "center" }}>
                                  {isloading ? <ClipLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#123abc'}
                                    loading={isloading}
                                  /> : "No Record Found"}

                                </td>
                              </tr>
                          }
                          </tbody>
                        </Table>
                        </div>
                      </InfiniteScroll>
                    </Card>
                  </div>
                </Row>
              </Container>
            </>
          );
  }

export default ListTemplates