import axios from 'axios';
import {useState, useEffect} from 'react';
import Swal from 'sweetalert2';



const useForm = (validate) => {

    // const [username, setUsername] = useState('');
    // const [email, setEmail] = useState('');
    // const [subject, setsubject] = useState('');
    // const [comments, setComments] = useState('');

    const [values, setValues] = useState({
        fname:'',
        lname:'',
        siteSelect: '',
        email: '',
        sAddress: '',
        sAddress2: '',
        city: '',
        state: '',
        postal: '',
        countrySelect: '',
        hrradio1: '',
        date: '',
        countryPhone: '',
        areaCode: '',
        phoneNumber: '',
       
    });
    
    // const [success, setSuccess]= useState(false);
    const [errors, setErrors]= useState({});

    const handleChange = e => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

   
   
    const handleSubmit = e => {
        e.preventDefault();
        // let name = e.target.username.value;
        // var email = e.target.email.value;
        // let subject = e.target.subject.value;
        // let comments = e.target.comments.value;
           
            // if(!values.fname.trim()){
            //     errors.fname = "Please enter in your first name"
            //     return
            // }
        if(values){
        console.log(values);}
        else{
            setErrors(validate(values));
        }
            // else if(values.username.length<4){
            //     errors.username="Name must not be less than 4 characters"
            //     return
            // }
        
            // else if(!values.lname.trim()){
            //     errors.lname = "Please fill in last name"
            //     return
            // }
        
            // else if(!values.email){
            //     errors.email = "Please enter your email"
            //     return
            // }
            // else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)){
                
               
            //     errors.email = "Email is invalid";
            //     return
                
            // }
           
            // else if(values.comments.length<1){
                
            //     // console.log("Name: "+ name + "\n" +"Email: " + email + "\n" + "Subject: " + subject);
            //     console.log(values);
            //     axios.post('https://622f22b73ff58f023c1717f3.mockapi.io/fakeData', values)
            //         .then(response =>{
            //             console.log(response)
            //         })
            //         .catch(error => {
            //             console.log(error)
                       
            //         })
                
            //         Swal.fire({
            //             position: 'center',
            //             icon: 'success',
            //             title: 'Your inquiry is received...',
            //             showConfirmButton: false,
            //             timer: 1700,
            //             width: '520px',
            //             background: '#1B98F5',
            //             heightAuto: false,
            //             color: '#ffffff',
            //             customClass: 'forSwal'
            //           })

            //        values.username='';
                   
            //        values.email= '';
            //        values.subject= '';
            // }

            // else{

            //     // setTimeout(()=>{
            //     //     setSuccess(true)
            //     // },2000)
                   
            //     console.log(values);
               
            //         Swal.fire({
            //             position: 'center',
            //             icon: 'success',
            //             title: 'Your inquiry is received...',
            //             showConfirmButton: false,
            //             timer: 1700,
            //             width: '520px',
            //             background: '#1B98F5',
            //             heightAuto: false,
            //             color: '#ffffff',
            //             customClass: 'forSwal'
            //           })

            //           values.username='';
            //           values.comments='';
            //           values.email= '';
            //           values.subject= '';

            // }     
        
    };


    
    
    return {handleChange, values, handleSubmit, errors};
}

export default useForm;