/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
// import Profile from "views/basicComp/Profile.js";
import Register from "views/basicComp/Register.js";
import Login from "views/basicComp/Login.js";
import ListTemplate from "views/templates/listtemplate/listTemplate";
import ListUsers from "views/userPool/listUserPool/listUserPool";
import EditTemplate from "views/templates/edittemplate/editTemplate";
import ImageUpload from "views/s3images/imageupload/imageUpload";
import SendEmail from "views/sendemail/sendEmail";
import Profile from 'views/basicComp/Profile';
// import Education from 'views/basicComp/education';
// import Bio from 'views/basicComp/bio';
// import Address from 'views/basicComp/address';
import userInfo from "views/basicComp/userInfo";
import HardwareRequest from "views/forms/hardwareRequest";
import HardwareRequest2 from "views/forms/hardwareRequest2";
import HardwareReturn from "views/forms/hardwareReturn";
import VisitSchedule from "views/mapsForms/visitSchedule";
import TherapySession from "views/mapsForms/therapySession";
import RaterSession from "views/mapsForms/raterSession";
import TimeOff from "views/timeOff/timeOff";
// import './routes.css';


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    view: "true"
  },
  
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/admin",
    view: "false"
  },
  
  // {
  //   path: "/education",
  //   name: "Profile",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Education,
  //   layout: "/admin",
  //   view: "false"
  // },
  {
    path: "/userInfo",
    name: "Profile",
    icon: "ni ni-circle-08 text-pink",
    component: userInfo,
    layout: "/admin",
    view: "false"
  },
  // {
  //   path: "/bio",
  //   name: "Profile",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Bio,
  //   layout: "/admin",
  //   view: "false"
  // },
  // {
  //   path: "/address",
  //   name: "Profile",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Address,
  //   layout: "/admin",
  //   view: "false"
  // },
  
  {
    path: "/list/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: ListUsers,
    layout: "/admin",
    view: "true"
  },
  {
    path: "/list/templates",
    name: "Templates",
    icon: "ni ni-single-copy-04 text-pink",
    component: ListTemplate,
    layout: "/admin",
    view: "true"
  },
  {
    path: "/image/upload",
    name: "Upload",
    icon: "ni ni-image text-purple",
    component: ImageUpload,
    layout: "/admin",
    view: "true"
  },
  // {
  //   name:'Forms',
  //   view: 'true',
  //   margin: '1000px'
  // },
  {
    path: "/hardwareRequest",
    name: "HRF",
    icon:" ni ni-single-copy-04",
    component: HardwareRequest,
    layout: "/admin",
    view: "false",
    id: 'hrf'
  },
  
  {
    path: "/hardwareRequest2",
    name: "Hardware Request Form",
    icon:" ni ni-single-copy-04",
    component: HardwareRequest2,
    layout: "/admin",
    view: "true"
  },
  {
    path: "/hardwareReturn",
    name: "Hardware Return Form",
    icon:" ni ni-single-copy-04",
    component: HardwareReturn,
    layout: "/admin",
    view: "true"
  },
  {
    path: "/visitSchedule",
    name: "MVSR",
    icon:" ni ni-single-copy-04",
    component: VisitSchedule,
    layout: "/admin",
    view: "true"
  },
  {
    path: "/therapySession",
    name: "Therapy Session Form",
    icon:" ni ni-single-copy-04",
    component: TherapySession,
    layout: "/admin",
    view: "false"
  },
  {
    path: "/raterSession",
    name: "Independent Rater Session Form",
    icon:" ni ni-single-copy-04",
    component: RaterSession,
    layout: "/admin",
    view: "false"
  },
  {
    path: "/timeOff",
    name: "Time Off Form",
    icon:" ni ni-single-copy-04",
    component: TimeOff,
    layout: "/admin",
    view: "true"
  },
  {
    path: "/login/:access_token?/:id_token?",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    view: "false"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    view: "false"
  },
  {
    path: "/template/:temp",
    name: "Template",
    component: EditTemplate,   
    layout: "/admin",
    view: "false"
  },
  {
    path: "/sendmail/:temp",
    name: "Send email",
    icon: "ni ni-email-83 text-yellow",
    component: SendEmail,
    layout: "/admin",
    view: "false"
  }
];
export default routes;
