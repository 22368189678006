import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../../components/Pool/userPool';
import { useHistory } from 'react-router-dom';

const SessionContext = createContext();

const Session = props => {

    const history = useHistory();  //History for Routes....

    const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            const token = session.getIdToken().getJwtToken()
            resolve({
              headers: {
                Authorization: token,
              },
              ...session
            });
          }
        });
      } else {
        reject("Log In");
      }
    });

    const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          console.log('onSuccess:DATA....', data);
          resolve(data);
        },

        onFailure: err => {
          console.error('onFailure:', err);
          reject(err);
        },

        newPasswordRequired: data => {
          console.log('newPasswordRequired:', data);
          resolve(data);
        }
      });
    });

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
          user.signOut();
          history.push(`/auth/login`)
        }
      }

    return (
    <SessionContext.Provider value={{
      authenticate,
      getSession,
      logout
    }}>
      {props.children}
    </SessionContext.Provider>
  );
}

export { Session, SessionContext };