import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {SessionContext} from '../basicComp/session';
import Swal from 'sweetalert2'
import Header from "../../components/Headers/Header.js";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Alert,
    Col,
  } from "reactstrap";


function SendEmail() {

    const history = useHistory();
    const { temp } = useParams();
    const [errors, setErrors] = useState('');
    const { getSession } = useContext(SessionContext); //For Session check
    const [token, setToken] = useState("false");
    const [emailTemp, setEmailTemp] = useState([]);
    const [toAddress, setToAddress] = useState([]);
    const [ccAddress, setCcAddress] = useState([]);
    const [rToAddress, setRToAddress] = useState([]);
    const [sourceEmail, setSourceEmail] = useState('');
    const [templateNames, setTemplateNames] = useState([]);
    const [templateData, setTemplateData] = useState("");
    const [tags, setTags] = useState([]);
    const [hide, setHide] = useState(false);
    // const [htmlData, setHtmlData] = useState('');

    useEffect(() => {
      if(temp)
          changeTemplate(temp);
        // getEmailTemplates();
        checkSession();
    },[]);

    const checkSession = () => {
      getSession()
      .then(session => {
        // console.log('Session:EDIT', session);
        // setStatus(true);
      })
      .catch(err => {
        history.push(`/auth/login`)
      })
    }

    // const getEmailTemplates = () => {
    //     // setIsLoading(true);
    //     // setMoreList(true);
    //     fetch(`https://api.samv.valisr.com/listTemplates/?token=${token}`,{
    //     method: "GET"
    //     // headers: {
    //     // "access-control-allow-origin" : "*",
    //     // "Content-type": "application/json; charset=UTF-8",
    //     // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    //     // }
    //   })
    //         .then(response => response.json())
    //         .then(data =>
    //             {
    //             if(Object.keys(data).length !== 0){
    //                 let root = data;
    //                 // console.log("roootttt....", root)
    //                 let result = root.TemplatesMetadata
    //                 // console.log("root.NextToken",root.NextToken)
    //                 // if(root && root.NextToken){
    //                 //   setToken(root.NextToken)
    //                 // }else{
    //                 //   setMoreList(false);
    //                 // }
    //                 setTemplateNames(result)
    //                 // setIsLoading(false);
    //             }
    //             }
    //         )
    //         .catch((error) => {
    //           console.error('Errors', error);
    //     })
    // }

    const changeTemplate = (templateName) => {
        // e.preventDefault();
        setHide(false);
        // let key = e.target.value;
        setEmailTemp(templateName);
        fetch(`https://api.samv.valisr.com/get-single-template/?tempName=${templateName}`,{
            method: 'GET'
            // headers: {
            //   'Content-Type': 'application/json',
            // }
        })
              .then(response => response.json())
              .then(data =>
                  {
                      let result = data.Template;
                      if(result.HtmlPart !== "")
                      {
                        let theHtml = result.HtmlPart;
                        let array = theHtml.split('{{')
                        .filter(val => val.includes('}}'))
                        .map(val => val.substring(0, val.indexOf('}}')));
                        setTags(array)
                      }
                  })
              .catch((error) => {
                    // setIsLoading(false);
                    Swal.fire('Error','Something went wrong!','error')
                    console.error('Errors', error);
              })
    }

    const addAddresses = (e, val) => {
        e.preventDefault()
        if (val === 'to'){
            let newValue = document.getElementById("Aaddr").value;
            setToAddress([...toAddress, newValue]);
            document.getElementById("Aaddr").value = "";
        }else if (val === 'cc'){
            let newValue = document.getElementById("Caddr").value;
            setCcAddress([...ccAddress, newValue]);
            document.getElementById("Caddr").value = "";
        }else{
            let newValue = document.getElementById("Raddr").value;
            setRToAddress([...rToAddress, newValue]);
            document.getElementById("Raddr").value = "";
        }
      };

    // const editAddr = (e,  item) => {
    //     // e.preventDefault();
    //     let newToAddresses = [...toAddress];
    //         newToAddresses[item] = e.target.value;
    //         setToAddress(newToAddresses)
    //     // document.getElementById("addr").value = value;

    // }  

    const sendEmail = (e) => {
        e.preventDefault();
        var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!toAddress.length){
            setErrors(`To address is required`);
            return
        }
        else if(toAddress.length){
            toAddress.map((val, index)=>{
                let result = emailRegex.test(val)
                if(!result)
                    setErrors(`This ("${val}") email address is not correct, check "To Addresses".`);
                    return;
            })
        }else if(ccAddress.length){
            toAddress.map((val, index)=>{
                let result = emailRegex.test(val)
                if(!result)
                setErrors(`This ("${val}") email address is not correct, check "Cc Addresses".`);
                return;
            })
        }else if(sourceEmail === ""){
            setErrors(`Sorce email is reqiuired field.`);
            return
        }else if(emailTemp === ""){
            setErrors('Template is a required field.')
            return
        }else if (templateData === ""){
            setErrors('Template Data is required field.')
        }else if (rToAddress.length){
            rToAddress.map((val, index)=>{
                let result = emailRegex.test(val)
                if(!result)
                    setErrors(`This ("${val}") email address is not correct, check "Reply To Addresses".`);
                    return
            })
        }else{
            setErrors("");
        }

        // console.log("temp Datttttttt",templateData)
        // console.log("temp DattttttttType...",typeof(templateData))
        let payload = {
            "ToAddresses": toAddress,
            "CcAddresses": ccAddress,
            "Source": sourceEmail,
            "Template": emailTemp,
            "TemplateData": templateData, //'{\"name\":\"test\", \"reset_password_link\": \"http://testLink\"}',
            "ReplyToAddresses": rToAddress
        }

        fetch('https://api.samv.valisr.com/sendTemplatedEmail',{
            method: 'POST',
            // headers: {
            //   'Content-Type': 'application/json',
            // },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data =>{
                  if(data.code == 200){
                    Swal.fire('Success', 'Mail sent successfully!', 'success')
                  }
                })
            .catch((error) => {
              Swal.fire('Error','Something went wrong!','error')
              console.error('Error:', error);
            });
        
    }

    const replaceVal = (e) => {
        e.preventDefault();
        let objData = {};
        // let newValue = document.getElementById("Aaddr").value;
        tags.map((tag)=>{
            console.log("Hit",tag)
            let newValue = document.getElementById(tag).value;
            objData[tag] = newValue;
        })
        let output = JSON.stringify(objData);
        console.log("outputoutputoutput0",output);
        setTemplateData(output)
        setHide(true);

        // console.log("objDataobjDataobjDataobjData",objData)

    }

    const cancel = (e) => {
      history.push(`/admin/list/templates`)
  }

    return(
        <>
            <Header />
            <Container className="mt--9" fluid>
        <Row>   
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h3 className="mb-0">Send email</h3>
                  </Col>
                  <Col className="text-right" xs="1">
                    <Button
                      color="danger"
                      onClick={(e) => cancel(e)}
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="text-right" xs="1">
                    <Button
                      color="primary"
                      onClick={e => sendEmail(e)}
                      size="sm"
                    >
                      Send
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {Object.keys(errors).length > 0 && errors ?
                <Alert className="alert-danger">
                    <strong>{errors}</strong>
                </Alert>
                :
                ""
              }
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Addresses
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            To Addresses
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="Aaddr"
                            placeholder="xyz@outlook.com"
                          />
                          <Button color="success" onClick={e => addAddresses(e,'to')} size="sm">Add</Button>
                            {/* <button onClick={e => addAddresses(e,'to')}> Add </button> */}
                            <ol>
                            {toAddress.map((email, Index) => {
                                return <li key={Index} > {email} </li>
                            })}
                            </ol>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Cc Addresses
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="Caddr"
                            placeholder="xyz@outlook.com"
                          />
                          <Button color="success" onClick={e => addAddresses(e,'cc')} size="sm">Add</Button>
                            <ol>
                            {ccAddress.map((email, Index) => {
                                return <li key={Index} > {email} </li>
                            })}
                            </ol>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">{"Source  & Template"}</h6>
                  <div className="pl-lg-4">
                  <Row>
                  <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Source Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="sourceEmail"
                            placeholder="xyz@mail.com"
                            type="text"
                            name="sourceEmail"
                            value={sourceEmail}
                            onChange={e => setSourceEmail(e.target.value)}
                          />
                        </FormGroup>
                   </Col>
                   <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Choose Template
                          </label>
                          <Input
                            disabled
                            className="form-control-alternative"
                            id="emailTemp"
                            type="text"
                            name="emailTemp"
                            value={emailTemp}
                          />
                          {/* <select className="form-select" aria-label="Default select example" onChange={e => changeTemplate(e)}>
                          <option defaultValue>Template Name</option>
                                {templateNames.map((template, index) =>
                                    <option key={index} value={template.Name} >{template.Name}</option>
                                )};
                         </select> */}
                        </FormGroup>
                    </Col>     
                  </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">{"Tags & Reply Addresses"}</h6>
                  <div className="pl-lg-4">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Tags
                          </label>
                            {/* <label>Tags</label> */}
                            {tags && tags.length !== 0 ?
                            <ol>
                            {tags.map((tag, Index) => {
                                return(
                                    <div  key={Index}> 
                                <li> {tag} </li>
                                <Input
                                    className="form-control-alternative" disabled={hide? true:false} id={tag} placeholder={tag} type="text"
                                    name="sourceEmail" />
                            </div>
                                )
                            })}
                            <Button color="primary" type="button" onClick={e => replaceVal(e)} >Set Values</Button>
                            </ol>
                            :
                            "No Tags"
                            }
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col md="12">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    Reply To Addresses
                                </label>
                            <Input
                                className="form-control-alternative"
                                id="Raddr"
                                placeholder="xyz@outlook.com"
                            />
                            <Button color="success" onClick={e => addAddresses(e,'reply')} size="sm">Add</Button>
                            <ol>
                                {rToAddress.map((email, Index) => {
                                    return <li key={Index} > {email} </li>
                                })}
                            </ol>
                            </FormGroup>
                        </Col>
                        </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
        </>
    )
}

export default SendEmail;