/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Login.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Alert,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import {SessionContext} from './session';
import { Link } from "react-router-dom";
import samLogo from './SAM.png';

function Login (props) {

  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  // const { access_token } = useParams();

  const { authenticate, getSession } = useContext(SessionContext);

  useEffect(() => {
    var url = window.location;
    console.log("url/.....0",url)
    var access_token = new URLSearchParams(url.search).get('access_token');
    var id_token = new URLSearchParams(url.search).get('id_token');
    console.log("access_token/.....0",access_token)
    console.log("id_token/.....0",id_token)
    if(access_token ){
      console.log("...//..")
      addSession(access_token, id_token)
    }
    checkSession();
  }, []);

  const addSession = (token, idTok) => {
    console.log("token...",token);
    console.log("idTokkk...",idTok);
    localStorage.setItem('Authorization', JSON.stringify(idTok));
    history.push(`/admin/index`);
  }

  const checkSession=()=>{
    getSession()
      .then((session) => {
        console.log('sesssion',session);
        history.push(`/admin/index`)
        // setStatus(true);
      })
      .catch(err => {
        console.log("No Session",err);
      })
  }
  
  const signinButton = (e) => {
    e.preventDefault();
    if (email === ""){
      setErrors("Email is a required field.")
    }else if (password === ""){
      setErrors("Password is a required field.")
    }else{
      authenticate(email, password)
      .then(data => {
        setErrors("")
        history.push(`/admin/index`)
        if(data && data.idToken){
          localStorage.setItem('userEmail', JSON.stringify(data.idToken.payload.email));
          localStorage.setItem('Authorization', JSON.stringify(data.idToken.jwtToken));
          // Swal.fire('Success','Successfully loged in!','success')
        }
        // if(session && session.headers)
        //   localStorage.setItem('Authorization', JSON.stringify(session.headers.Authorization))
        // console.log('Logged in!', data.idToken.payload.email);
      })
      .catch(err => {
        Swal.fire('Error','Incorrect username or password!','error')
        setErrors("");
        console.error('Failed to login!', err);
      })
    }
  }

  // const googleLogin = (e) => {
  //   // setErrors("Email is a required field.")
  //   window.open("https://samv-valisr-google-login.auth.us-east-1.amazoncognito.com/login?client_id=3mgscakofun3vnql1imeh4ohem&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://samv.valisr.com/auth/login")
  //   fetch(`https://mydomain.auth.us-east-1.amazoncognito.com/oauth2/authorize?
  //   response_type=code&
  //   client_id=ad398u21ijw3s9w3939&
  //   redirect_uri=https://YOUR_APP/redirect_uri&
  //   state=STATE&
  //   scope=openid+profile+aws.cognito.signin.user.admin`,{
  //           method: 'GET',
  //           // headers: {
  //           //   'Content-Type': 'application/json',
  //           // }
  //         })
  //             .then(response => response.json())
  //             .then(data =>{
  //               console.log("data.....",data)
  //             })
  //             .catch((err) => {
  //               console.log("error...",err)
  //             })
  // }

  // const responseGoogle = (response) => {
  //   console.log("response......................",response);
  // };

  return (
    <>
        
        {/* <Col className="justify-content-end" lg="10" md="7" > */}
          {/* <Row className="justify-content-center pb-5" >
        <img src={samLogo} alt="SAM" id="samloginLogo"/>
        </Row> */}
        <Card className="bg-blue shadow border-0 m-5" >
          <div className="bg-transparent m-3">
         
            <Form role="form" className='mt-3'>
              <FormGroup className="mb-4">
                <Label className="text-white" for="exampleEmail">
                  Email
                </Label>
                <InputGroup className="input-group-alternative">
                
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="email"
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label className="text-white" for="exampleEmail">
                  Password
                </Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    placeholder="Your Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-3 text-blue font-weight-bold" color="white" type="button" onClick={e => signinButton(e)}>
                  Sign in
                </Button>
              </div>
            </Form>
          </div>
          <hr className="bg-neutral" />
          {Object.keys(errors).length > 0 && errors ?
                <Alert className="alert-danger">
                    <strong>{errors}</strong>
                </Alert>
                :
                ""
              }
          <CardBody className="px-lg-5 ">
          <div className="text-neutral text-center mt--3 mb-3">
            <small>Or Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
            {/* <GoogleLogin
            clientId="592237477710-0jcs12570sf4pi028ct09sklcg7aks6c.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            /> */}
            <Button
              className="btn-neutral btn-icon"
              color="white"
              // href="#pablo"
              onClick={()=> window.open("https://samv-valisr-google-login.auth.us-east-1.amazoncognito.com/login?client_id=3mgscakofun3vnql1imeh4ohem&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://samv.valisr.com/auth/login", "_blank")}
              // onClick={(e) => googleLogin(e)}
            >
              <span className="btn-inner--icon">
                <img
                  alt="..."
                  src={
                    require("../../assets/img/icons/common/google.svg")
                      .default
                  }
                />
              </span>
              <span className="btn-inner--text">Google</span>
            </Button>
            </div>    
          </CardBody>
        </Card>
        <Row className="mt--4 justify-content-center ml-4">
          {/* <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col> */}
          <Col className="text-dark font-weight-bold" xs="6">
            <Link to="/auth/register"
              className="text-dark"
              id="createAccount"
            >
              <strong>Create new account <i class="fa-solid fa-caret-right"></i></strong>
            </Link>
          </Col>
        </Row>
        
      {/* </Col> */}
     
   
    </>
  );
};

export default Login;
