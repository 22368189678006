export default function validate(values){
    let errors =[];

    if(!values.fname.trim()){
        errors.fname = "Please fill in your name"
    }

    if(!values.lname.trim()){
        errors.lname = "Please fill in your name"
    }
    if(!values.siteSelect.trim()){
        errors.siteSelect = "Select something"
    }
    
    if(!values.email.trim()){
        errors.email = "Enter your email"
    }

    if(!values.sAddress.trim() || !values.sAddress2.trim() || !values.city.trim() || !values.state.trim() || !values.postal.trim() || !values.countrySelect.trim()){
        errors.sAddress = "Please Select all fields"
    }

    if(!values.countryPhone.trim() || !values.areaCode.trim() || !values.phoneNumber.trim()){
        errors.countryPhone = "Please enter all fields"
    }

    if(!values.hrradio1.trim()){
        errors.hrradio1 = "Select any option"
    }

    if(!values.date.trim()){
        errors.date = "Select delivery date"
    }

    // else if(values.username.length<4){
    //     errors.username="Name must not be less than 4 characters"
    // }

    // if(!values.subject.trim()){
    //     errors.subject = "Please fill in subject"
    // }

    // if(!values.comments.trim()){
    //     errors.comments = "Please enter in comments in detail"
    // }

    // else if(values.comments.length<20){
    //     errors.comments = "Comments should be more than 20 characters"
    // }

    // if(!values.email){
    //     errors.email = "Please enter your email"
    // }
    // else if(!/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(values.email)){
    //     errors.email = "Email is invalid";
    // }
    return errors;
}

