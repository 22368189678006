import UserHeader from "components/Headers/UserHeader";
import React, {useEffect} from "react";
import useForm from './useForm';
import validate from './validate';
import SignaturePad from 'react-signature-canvas'
// import { Canvas } from './Canvas'
// import { ClearCanvasButton } from './ClearCanvasButton';
import {
    CardHeader,
    Container,
    Card,
    Row, 
    Col,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    FormText,
} from 'reactstrap';
import './timeOff.css';

const TimeOff = () => {

   

    useEffect(() => {
        const canvas = document.querySelector('#canvas');
        const ctx = canvas.getContext("2d");

        canvas.height = window.innerHeight;
        canvas.width = window.innerWidth;
        canvas.style.width = '210px';
        canvas.style.height = '100px';
        canvas.style.border = '2px solid';

        let painting = false;
        function startPosition(e){
            painting = true;
            draw(e);
        }

        function endPosition(){
            painting = false;
            ctx.beginPath();
        }

        function draw(e){
            if(!painting) return;
            ctx.lineWidth = 20;
            ctx.lineCap = "round";
            ctx.strokeStyle = "black";
            ctx.lineTo(e.clientX, e.clientY);
            ctx.stroke();
            ctx.textAlign = "center";
            ctx.font = "20px";
           
        }

        canvas.addEventListener("mousedown", startPosition);
        canvas.addEventListener("mouseup", endPosition);
        canvas.addEventListener("mousemove", draw);

    }, 1);

    function showFields(){
        document.getElementById('displayFields').style.display="block";
    }

    function hideFields(){
        document.getElementById('displayFields').style.display="none";
    }

    // const sigPad = {};
    // const clear = () => {
    //     this.sigPad.clear()
    // }

    const clearCanvas=()=>{
        const canvas = document.querySelector('#canvas');
    const context = canvas.getContext("2d")
    context.fillStyle = "white"
    context.fillRect(0, 0, canvas.width, canvas.height)
    }

    return (
        <>
        <UserHeader />
        {/* Page Content */}
        <Container className="mt--7 pb-4" fluid>
            <Row className="justify-content-center">
                <Col lg="8">
                   <Card className="pt-4 pl-4 pr-4 pb-0 shadow">
                        <div className="px-4 pt-4 ">
                            <h1 className="display-3">Time-Off Request Form</h1>
                            <h3>Please submit this form for approval at least two weeks in advance of your preferred Time-Off dates</h3>
                            <hr />
                        </div>
                        <CardBody>
                            <Form id="form">
                                <Label className="font-weight-bold pt--5">Who is requesting Time-Off: <span className="text-red">&nbsp;*</span></Label>
                                <Row>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="fname"
                                            bsSize="sm"
                                        />
                                        <FormText><span className="text-primary">First Name</span></FormText>
                                    </Col>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="lname"
                                            bsSize="sm"
                                        />
                                        <FormText><span className="text-primary">Last Name</span></FormText>
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col>
                                        <Label className="font-weight-bold">Title of Employee Requesting Time-Off: <span className="text-red">&nbsp;*</span></Label>
                                        <Input
                                            type="text"
                                            name="employeeTitle"
                                            bsSize="sm"
                                        />
                                        
                                    </Col>
                                    <Col>
                                        <Label className="font-weight-bold">Department <span className="text-red">&nbsp;*</span></Label>
                                        <Input
                                            type="text"
                                            name="department"
                                            bsSize="sm"
                                        />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col>
                                    <FormGroup tag="fieldset" id="timeRadio">
                                    <legend className="mt-2 font-weight-bold">
                                        What is the reason for your request? <span className="text-red">&nbsp;*</span>
                                    </legend>
                                    {/* {errors.radio && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.radio}</p>} */}

                                    <FormGroup check >
                                      
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    
                                    {' '}
                                    <label check>
                                      <span className="font-weight-normal ">Family Leave</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Funeral/Bereavement Leave</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Medical Leave</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Jury Duty</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Personal Leave</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Vacation</span>
                                    </label>

                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Professional Development</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={showFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">Other</span>
                                    </label>
                                    <Input type="text" 
                                           className="mt-2 ml--4"
                                           placeholder="Please type other option here.."
                                           id="displayFields" />
                                    </FormGroup>
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="exampleDate" className="font-weight-bold" >
                                            Starting Date <span className="text-red">&nbsp;*</span>
                                            </Label>
                                            {/* {errors.date && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.date}</p>}  */}

                                            <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            bsSize="sm"
                                            // value={values.date}
                                            // onChange={handleChange}
                                            />
                                            <FormText>
                                                <span className="text-primary">
                                               Date
                                                </span>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="exampleDate" className="font-weight-bold" >
                                            Ending Date <span className="text-red">&nbsp;*</span>
                                            </Label>
                                            {/* {errors.date && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.date}</p>}  */}

                                            <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            bsSize="sm"
                                            // value={values.date}
                                            // onChange={handleChange}
                                            />
                                            <FormText>
                                                <span className="text-primary">
                                               Date
                                                </span>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Label for="exampleDate" className="font-weight-bold" >
                                        Select the Date on which you plan on returning to work <span className="text-red">&nbsp;*</span>
                                    </Label>
                                    <Col lg="6">
                                        <FormGroup>
                                            
                                            {/* {errors.date && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.date}</p>}  */}

                                            <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            bsSize="sm"
                                            // value={values.date}
                                            // onChange={handleChange}
                                            />
                                            <FormText>
                                                <span className="text-primary">
                                               Date
                                                </span>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col>
                                        <Label className="font-weight-bold">Total Number of Days Requested <span className="text-red">&nbsp;*</span></Label>
                                        <Input 
                                            type="text"
                                            name="totalDays"
                                            bsSize="sm"
                                        />
                                    </Col>
                                </Row>
                                <br />

                                <Label className="font-weight-bold">Name of staff who will be your back-up while you are away:</Label>
                                <Row>
                                    <Col>
                                        <Input 
                                            type="text" 
                                            name="fname" 
                                            bsSize="sm"
                                            // value={values.fname}
                                            // onChange={handleChange} 
                                        />
                                        <FormText><span className="text-primary">First Name</span></FormText>
                                    </Col>
                                    <Col>
                                        <Input 
                                            type="text" 
                                            name="lname" 
                                            bsSize="sm"
                                            // value={values.lname}
                                            // onChange={handleChange}
                                        />
                                        <FormText><span className="text-primary">Last Name</span></FormText>
                                    </Col>
                                </Row>
                                <br /><br />

                                <Row>
                                <Col lg="5" className="offset-1">
                                      <canvas id="canvas"></canvas>
                                        <FormText>
                                        <div className="ml-8">
                                          <Button size="xs"  color="danger" onClick={clearCanvas}>Clear</Button>
                                          </div>
                                        </FormText>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="exampleDate" className="font-weight-bold" >
                                            Date of Request <span className="text-red">&nbsp;*</span>
                                            </Label>
                                            {/* {errors.date && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.date}</p>}  */}

                                            <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            bsSize="sm"
                                            // value={values.date}
                                            // onChange={handleChange}
                                            />
                                            <FormText>
                                                <span className="text-primary">
                                               Date
                                                </span>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <br />

                                <Row>
                                    <div className="text-center mt--3">
                                        <Button color="primary">
                                        Submit Request
                                        </Button>
                                    </div>
                                </Row>
                                <br /><br />


                                <Row>
                                    <Col className="display-4">
                                        APPROVAL (For authorized use only)
                                    </Col>
                                   
                                </Row>
                                <hr className=""/>

                                <Row>
                                    <Col>
                                    <FormGroup tag="fieldset">
                                    <legend className="mt-2 font-weight-bold">
                                       Approved: <span className="text-red">&nbsp;*</span>
                                    </legend>
                                    {/* {errors.radio && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.radio}</p>} */}

                                    <FormGroup check>
                                      
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        
                                        onClick={hideFields}
                                    />
                                    
                                    {' '}
                                    <label check>
                                      <span className="font-weight-normal ">Yes</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check>
                                    <span className="font-weight-normal">No</span>
                                    </label>
                                    </FormGroup>
                                    </FormGroup>
                                    </Col>
                                </Row>

                                <br />

                                <Row>
                                <Col lg="5" className="offset-1">
                                      <canvas id="canvas"></canvas>
                                        <FormText>
                                        <div className="ml-8">
                                          <Button size="xs"  color="danger" onClick={clearCanvas}>Clear</Button>
                                          </div>
                                        </FormText>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="exampleDate" className="font-weight-bold" >
                                            Date of Approval <span className="text-red">&nbsp;*</span>
                                            </Label>
                                            {/* {errors.date && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.date}</p>}  */}

                                            <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            bsSize="sm"
                                            // value={values.date}
                                            // onChange={handleChange}
                                            />
                                            <FormText>
                                                <span className="text-primary">
                                               Date
                                                </span>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <div className="text-center">
                                    <Button color="primary" size="md">
                                        Submit Approval
                                    </Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                   </Card>
                </Col>
            </Row>

        </Container>
        </>
    );
};

export default TimeOff;