import UserHeader from "components/Headers/UserHeader";
import React,{useState} from "react";
import useForm from './useForm';
import validate from './validate';
import {
    CardHeader,
    Container,
    Card,
    Row, 
    Col,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    FormText,
    Collapse
} from 'reactstrap';
import {Link} from 'react-router-dom';
import './form.css';

const HardwareReturn = () => {
    const {handleChange, values,handleSubmit, errors } = useForm(validate);
    
    function buttonsAppear(e){
        e.preventDefault();
        if(document.getElementById('buttons').style.display=="block"){
            document.getElementById('buttons').style.display="none";
        }
        else{
            document.getElementById('buttons').style.display="block";
            document.getElementById('buttons').style.transitionDuration="3s";
            document.getElementById('buttons').style.transitionTimingFunction="linear";
        }
       
    }

    function showFields(){
        document.getElementById('displayFields').style.display="block";
    }

    function hideFields(){
        document.getElementById('displayFields').style.display="none";
    }

    const [basic, setBasic] = useState(true);
    const toggleBasic = () => {
        setBasic(!basic);
        setReturns(false);
    }

    const [returns, setReturns] = useState(false);
    const toggleReturn = () => {
        setReturns(!returns);
        setBasic(false);
    }

    return(
        <>
        <UserHeader />
        {/* Page Content */}
        <Container className="mt--7 pb-4" fluid>
            <Row className="justify-content-center">
                <Col lg="8">
                    <Card className="p-4 shadow">
                        <div className="px-4 pt-4">
                            <h1 className="display-3 text-default">Please provide the following information: </h1>
                            <hr />
                        </div>
                        <CardBody className="mt--5 pb-1">
                            <Form onSubmit={handleSubmit} id="form">
                            <div >
                            <Row className="p-3 h-100 rounded">
                                <Col className="bg-default text-white pt-2 rounded">
                                <Label color="primary" onClick={toggleBasic} >
                                <i class="fa-solid fa-circle-chevron-down fa-lg"></i>&nbsp; Basic Information
                            </Label>
                                </Col>                           
                            </Row>
                            <Collapse isOpen={basic} >
                            <Row className="justify-content-center mt-4">
                                <Col lg="6">
                                    <FormGroup required>
                                        <Label
                                            className="font-weight-bold"
                                            htmlFor="input-first-name"
                                           
                                        >
                                        First name<span className="text-red">&nbsp;*</span>
                                        </Label>
                                        <Input
                                           
                                            //   defaultValue="Lucky"
                                            name="fname"
                                            placeholder="First name"
                                            type="text"
                                            value={values.fname}
                                            onChange={handleChange} 
                                            bsSize="sm"
                                        />
                                        {errors.fname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.fname}</p>}
                        
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label
                                            className="font-weight-bold"
                                            htmlFor="input-last-name"
                                           
                                        >
                                        Last name<span className="text-red">&nbsp;*</span>
                                        </Label>
                                        <Input
                                            
                                            name="lname"
                                            placeholder="Last name"
                                            type="text"
                                            value={values.lname}
                                            onChange={handleChange} 
                                            bsSize="sm"
                                        />
                                        {errors.lname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.lname}</p>} 

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <FormGroup>
                                        <label className="font-weight-bold">
                                        Select Site<span className="text-red">&nbsp;*</span>
                                        </label>
                                        <Input
                                        id="exampleSelect"
                                        name="siteSelect"
                                        type="select"
                                        value={values.siteSelect}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                        >
                                        <option>Please Select</option>
                                        <option>01. Zen Therapeutic Solutions</option>
                                        <option>02. Aguazul Bluewater, Inc.</option>
                                        <option>03. Wholeness Center</option>
                                        <option>04. New School Research, LLC</option>
                                        <option>05. Ray Worthy Psychiatry, LLC</option>
                                        <option>06. UC San Francisco</option>
                                        <option>07. San Francisco Insight & Integration Center</option>
                                        <option>09. UW Madison</option>
                                        <option>10. New York University</option>
                                        <option>11. New York Private Practice</option>
                                        <option>12. Trauma Research Center</option>
                                        <option>13. British Columbia Centre for Substance Use</option>
                                        <option>14. Dr. Simon Amar, Inc.</option>
                                        <option>15. Israel - Beer Yaakov</option>
                                        <option>16. Israel - Tel Hashomer</option>
                                        <option>17. United Kingdom - Cardiff</option>
                                        <option>18. Netherlands - Stichting Centrum '45/Arq</option>
                                        <option>19. Netherlands - Maastricht University</option>
                                        <option>20. Czech Republic - Klecany</option>
                                        <option>21. United Kingdom - London</option>
                                        <option>22. Portugal - Lisbon</option>
                                        <option>23. Norway - Moss</option>
                                        <option>24. Germany - Berlin</option>
                                        <option>25. Germany - Hamburg</option>
                                        <option>26. Finland</option>
                                        <option>Non-site Home Address</option>
                                        <option>Non-site Business Address</option>

                                        </Input>
                                        {errors.siteSelect && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.siteSelect}</p>} 

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Row>
                                        <Col>
                                        <label className="font-weight-bold">
                                            Phone Number<span className="text-red">&nbsp;*</span>
                                           
                                        </label>
                                        </Col>
                                        <Col lg="7">
                                        {errors.countryPhone && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.countryPhone}</p>} 

                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Input
                                                 placeholder="+92"
                                                 type="text"
                                                 name="countryPhone"
                                                 value={values.countryPhone}
                                                 onChange={handleChange}
                                                 bsSize="sm"
                                                />
                                                <FormText>
                                                    <span className="text-primary">
                                                    Country
                                                    </span>
                                                </FormText>
                                            </Col>
                                            <Col>
                                                <Input
                                              placeholder="300"
                                              type="text"
                                              name="areaCode"
                                              value={values.areaCode}
                                              onChange={handleChange}
                                              bsSize="sm"
                                                />
                                                <FormText>
                                                <span className="text-primary">
                                                    Area Code
                                                </span>
                                                </FormText>
                                            </Col>
                                            <Col>
                                                <Input
                                               placeholder="1234567"
                                               type="text"
                                               name="phoneNumber"
                                               value={values.phoneNumber}
                                               onChange={handleChange}
                                               bsSize="sm"
                                                />
                                                <FormText>
                                                <span className="text-primary">
                                                    Phone Number
                                                </span>
                                                </FormText>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt--2">
                                <Col lg="6">
                                <FormGroup>
                                    <Label for="exampleEmail" className="font-weight-bold">
                                    Email <span className="text-red">&nbsp;*</span>
                                    </Label>
                                    <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="ex: myname@example.com"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    bsSize="sm"
                                    />
                                    <FormText>
                                    <span className="text-primary">
                                        example@example.com
                                    </span>
                                    </FormText>
                                    {errors.email && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.email}</p>} 

                                </FormGroup>
                                </Col>
                            </Row>
                            </Collapse>
                            </div>
                            {/* <Row className="mt-4 bg-gray align-items-center border-radius-50 rounded">
                                <Col lg="11" className="pt-3 pb-2 pl-4">
                                    <h2 className="text-white">Hardware Request Details</h2>
                                </Col>
                                <Col className="">
                                <i class="fa-solid fa-circle-arrow-down fa-xl text-white"></i>
                                </Col>
                            </Row> */}
                           
                            <div >
                            <Row className="p-3 h-100 rounded">
                                <Col className="bg-default text-white pt-2 rounded">
                                <Label color="primary" onClick={toggleReturn} >
                                <i class="fa-solid fa-circle-chevron-down fa-lg"></i>&nbsp; Reason for Return
                            </Label>
                                </Col>                           
                            </Row>
                            <Collapse isOpen={returns}>
                            <Row>
                                <Col>
                                    <h1 className="display-4">Please Note: </h1>
                                    <h4 className="text-primary">Prior to shipping any hardware an ACAM Hardware Return Form (TMPL_MAPS_RETURN_etc) must be completed. You will receive an email with the required template and detailed instructions on returning the kit shortly.</h4>
                                </Col>
                            </Row>
                        
                            <hr />
                            <Row>
                                <Col>
                                  
                                    <FormGroup tag="fieldset">
                                    <legend className="my-3 font-weight-bold">
                                       Reason for Return (select all that apply) 
                                    </legend>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        id="exampleCheck"
                                        name="check"
                                        type="checkbox"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check className="font-weight-normal" id="smallLabel">
                                      Completed Research Program/Study
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        id="exampleCheck"
                                        name="check"
                                        type="checkbox"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span >Replacement Received(returning old hardware)</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                       id="exampleCheck"
                                       name="check"
                                       type="checkbox"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span>Returning individual component</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        id="exampleCheck"
                                        name="check"
                                        type="checkbox"
                                        onClick={showFields}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span>Other</span>
                                    </label>
                                    <Input type="text" 
                                           className="mt-2 ml--4"
                                           placeholder="Please type other option here.."
                                           id="displayFields" />
                                    </FormGroup>
                                   
                                </FormGroup>
                                   
                                </Col>
                            </Row>
                            </Collapse>
                            </div>
                            

                            {/* <Row className="mt-4 bg-gray align-items-center  rounded cursor-pointer" onClick={buttonsAppear}>
                                <Col lg="11" className="pt-3 pb-2 pl-4">
                                    <h2 className="text-white">Please check the above information, then click here.</h2>
                                </Col>
                                <Col className="">
                                <i class="fa-solid fa-circle-arrow-down fa-xl text-white"></i>
                                </Col>
                            </Row> */}

                          
                            <div className="text-center" >
                               
                                    <Link to="/admin/hardwareRequest">
                                        <Button
                                        color="danger"
                                        // size="md"
                                        >
                                            Back
                                        </Button>
                                    </Link>
                                    &nbsp;&nbsp;
                                    {/* <Link to="/admin/hardwareRequest"> */}
                                        <Button
                                        color="primary"
                                        // size="md"
                                      
                                        >
                                            Submit
                                        </Button>
                                    {/* </Link> */}
                                
                            </div>
                            
                            </Form>
                        </CardBody>     
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default HardwareReturn;