/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import "./Register.css";
// reactstrap components
import {
  Button,
  Card,
  Row,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import userPool from '../../components/Pool/userPool';



function Register(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signupButton = (e) => {
    e.preventDefault();
    userPool.signUp(email, password, [], null, (err, data)=>{
      if (err) {
        Swal.fire('Error','Incorrect username or password!','error')
        // console.error('Error',err);
      }else{
        Swal.fire('Success','Registered Successfully!','success')
        // console.log('Registered successfully',data);
      }
    });
  }

  return (
    <>
     
        <Card className="bg-blue shadow border-0 m-5">
          <div className="bg-transparent m-3">
          
            <Form role="form" className='mt-3'>
              <FormGroup  className="mb-4">
              <Label className="text-white" for="exampleEmail">
                  Email
                </Label>
                <InputGroup className="input-group-alternative ">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="email"
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
              <Label className="text-white" for="exampleEmail">
                  Password
                </Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div className="text-center">
                <Button className="mt-4" color="white" type="button" onClick={e => signupButton(e)}>
                  Signup
                </Button>
              </div>
            </Form>
          </div>
          <hr className="bg-neutral" />
          <CardBody className="px-lg-5">
          <div className="text-neutral text-center mt--3 mb-3">
              <small>Sign up with</small>
            </div>
            <div className="text-center">
              {/* <Button
                className="btn-neutral btn-icon mr-4"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button> */}
              <Button
                className="btn-neutral btn-icon"
                color="white"
                // href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardBody>
        </Card>
        <Row className="mt--4 justify-content-center " >
          <Col className="text-dark font-weight-bold" lg="7" xs="6">
            
              <strong>Already have an account? 
              <Link to="/auth/login"
              className="text-dark"
            >
                <span className="text-blue ml-1">Sign in</span>
                </Link>
                </strong>
           
          </Col>
        </Row>
     
    </>
  );
};

export default Register;
