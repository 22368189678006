import UserHeader from "components/Headers/UserHeader";
import React, { useState, useRef, useEffect } from "react";
import useForm from './useForm';
import validate from './validate';

import {
    CardHeader,
    Container,
    Card,
    Row, 
    Col,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    FormText,
    Collapse,
} from 'reactstrap';
import './form.css';
import {Link} from "react-router-dom";

import DataGridXL from "@datagridxl/datagridxl2";

const HardwareRequest = () => {

    const {handleChange, values,handleSubmit, errors } = useForm(validate);

    function buttonsAppear(e){
        e.preventDefault();
        if(document.getElementById('buttons').style.display=="block"){
            document.getElementById('buttons').style.display="none";
        }
        else{
            document.getElementById('buttons').style.display="block";
            document.getElementById('buttons').style.transitionDuration="3s";
            document.getElementById('buttons').style.transitionTimingFunction="linear";
        }
       
    }

    function showFields(){
        document.getElementById('displayFields').style.display="block";
    }
    function showFields1(){
        document.getElementById('displayFields1').style.display="block";
    }
    function showFields2(){
        document.getElementById('displayFields2').style.display="block";
    }
    function hideFields(){
        document.getElementById('displayFields').style.display="none";
    }
    function hideFields1(){
        document.getElementById('displayFields1').style.display="none";
    }
    function hideFields2(){
        document.getElementById('displayFields2').style.display="none";
    }

    function show(){
        if(document.getElementById('displayFields').style.display="none"){
            document.getElementById('displayFields').style.display="block";
        }
        else{
            document.getElementById('displayFields').style.display="none";
        }
    }

    const [basic, setBasic] = useState(true);
    const toggleBasic = () => {
        setBasic(!basic);
        setAddress(false);
        setAdditional(false);
    }


    const [address, setAddress] = useState(false);
    const toggleAddress = () => {
        setAddress(!address);
        setBasic(false);
        setAdditional(false);
    }

    const [additional, setAdditional] = useState(false);
    const toggleAdditional = () => {
        setAdditional(!additional);
        setBasic(false);
        setAddress(false);
    }

    
    
    return(
        <>
        <UserHeader />
        {/* {Page Content} */}
        <Container className="mt--7 pb-4" fluid>
            <Row className="justify-content-center">
                <Col lg="8">
                    <Card className="p-4 shadow">
                        <div className="px-4 pt-4">
                            <h1 className="display-3 text-default">Hardware Request Form</h1>
                            <hr />
                        </div>
                        <CardBody className="mt--5 pb-1">
                            <Form onSubmit={handleSubmit} id="form">
                            <div >
                            <Row className="p-3 h-100 rounded">
                                <Col className="bg-default text-white pt-2 rounded">
                                <Label color="primary" onClick={toggleBasic} >
                                <i class="fa-solid fa-circle-chevron-down fa-lg"></i>&nbsp; Basic Information
                            </Label>
                                </Col>                           
                            </Row>
                            <Collapse isOpen={basic} horizontal>
                            <Row className="justify-content-center mt-2" id="toggles">
                                <Col lg="6">
                                    <FormGroup required>
                                        <Label
                                            className="font-weight-bold"
                                            htmlFor="input-first-name"
                                           
                                        >
                                        First name<span className="text-red">&nbsp;*</span>
                                        </Label>
                                        <Input
                                           
                                            //   defaultValue="Lucky"
                                            // id="input-first-name"
                                            name="fname"
                                            placeholder="First name"
                                            type="text"
                                            value={values.fname}
                                            onChange={handleChange} 
                                            bsSize="sm"
                                        />
                                        {errors.fname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.fname}</p>}
                        
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label
                                            className="font-weight-bold"
                                            htmlFor="input-last-name"
                                           
                                        >
                                        Last name<span className="text-red">&nbsp;*</span>
                                        </Label>
                                        <Input
                                            
                                            //   defaultValue="Jesse"
                                            // id="input-last-name"
                                            name="lname"
                                            placeholder="Last name"
                                            type="text"
                                            value={values.lname}
                                            onChange={handleChange} 
                                            bsSize="sm"
                                        />
                                        {errors.lname && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.lname}</p>} 

                                    </FormGroup>
                                </Col>
                               
                            </Row>
                            
                            <Row className="mt-1">
                                <Col>
                                    <FormGroup>
                                        <label className="font-weight-bold">
                                        Select Site<span className="text-red">&nbsp;*</span>
                                        </label>
                                        <Input
                                        id="exampleSelect"
                                        name="siteSelect"
                                        type="select"
                                        value={values.siteSelect}
                                        onChange={handleChange} 
                                        bsSize="sm"
                                        >
                                        <option>Please Select</option>
                                        <option>01. Zen Therapeutic Solutions</option>
                                        <option>02. Aguazul Bluewater, Inc.</option>
                                        <option>03. Wholeness Center</option>
                                        <option>04. New School Research, LLC</option>
                                        <option>05. Ray Worthy Psychiatry, LLC</option>
                                        <option>06. UC San Francisco</option>
                                        <option>07. San Francisco Insight & Integration Center</option>
                                        <option>09. UW Madison</option>
                                        <option>10. New York University</option>
                                        <option>11. New York Private Practice</option>
                                        <option>12. Trauma Research Center</option>
                                        <option>13. British Columbia Centre for Substance Use</option>
                                        <option>14. Dr. Simon Amar, Inc.</option>
                                        <option>15. Israel - Beer Yaakov</option>
                                        <option>16. Israel - Tel Hashomer</option>
                                        <option>17. United Kingdom - Cardiff</option>
                                        <option>18. Netherlands - Stichting Centrum '45/Arq</option>
                                        <option>19. Netherlands - Maastricht University</option>
                                        <option>20. Czech Republic - Klecany</option>
                                        <option>21. United Kingdom - London</option>
                                        <option>22. Portugal - Lisbon</option>
                                        <option>23. Norway - Moss</option>
                                        <option>24. Germany - Berlin</option>
                                        <option>25. Germany - Hamburg</option>
                                        <option>26. Finland</option>
                                        <option>Non-site Home Address</option>
                                        <option>Non-site Business Address</option>

                                        </Input>
                {errors.siteSelect && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.siteSelect}</p>} 

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <label className="font-weight-bold">
                                                Phone Number<span className="text-red">&nbsp;*</span>
                                                </label>
                                            </Col>
                                            <Col lg="7">
                                            {errors.countryPhone && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.countryPhone}</p>} 
                                            </Col>
                                        </Row>
                                       
                                     
                                        <Row>
                                            <Col>
                                                <Input
                                                placeholder="+92"
                                                type="text"
                                                name="countryPhone"
                                                value={values.countryPhone}
                                                onChange={handleChange}
                                                bsSize="sm"
                                                />
                                                <FormText>
                                                    <span className="text-primary">
                                                    Country
                                                    </span>
                                                </FormText>
                                            </Col>
                                            <Col>
                                                <Input
                                                placeholder="300"
                                                type="text"
                                                name="areaCode"
                                                value={values.areaCode}
                                                onChange={handleChange}
                                                bsSize="sm"
                                                />
                                                <FormText>
                                                <span className="text-primary">
                                                    Area Code
                                                </span>
                                                </FormText>
                                            </Col>
                                            <Col>
                                                <Input
                                                placeholder="1234567"
                                                type="text"
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                bsSize="sm"
                                                />
                                                <FormText>
                                                <span className="text-primary">
                                                    Phone Number
                                                </span>
                                                </FormText>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt--2">
                                <Col lg="6">
                                <FormGroup>
                                    <Label for="exampleEmail" className="font-weight-bold">
                                    Email <span className="text-red">&nbsp;*</span>
                                    </Label>
                                    <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="ex: myname@example.com"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    bsSize="sm"
                                    />
                                    <FormText>
                                    <span className="text-primary">
                                        example@example.com
                                    </span>
                                    </FormText>
                                    {errors.email && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.email}</p>} 

                                </FormGroup>
                                </Col>
                            </Row>
                            </Collapse>
                            </div>
                            {/* <Row className="mt-4 bg-gray align-items-center border-radius-50 rounded">
                                <Col lg="11" className="pt-3 pb-2 pl-4">
                                    <h2 className="text-white">Hardware Request Details</h2>
                                </Col>
                                <Col className="">
                                <i class="fa-solid fa-circle-arrow-down fa-xl text-white"></i>
                                </Col>
                            </Row> */}
                            <div >
                            <Row className="p-3 h-100 rounded">
                                <Col className="bg-default text-white pt-2 rounded">
                                <Label color="primary" onClick={toggleAddress} >
                                <i class="fa-solid fa-circle-chevron-down fa-lg"></i>&nbsp; Address Information
                            </Label>
                                </Col>                           
                            </Row>
                            <Collapse isOpen={address} >
                            <Row className="mt-2">
                                <Col lg="6">
                                   
                                        <FormGroup>
                                            <Label className="font-weight-bold">Location name for packing label</Label>
                                            <Input type="text" bsSize="sm"></Input>
                                            <FormText ><span className="text-primary">First line of address, e.g. site name in local language, name of business receiving package, etc.</span></FormText>
                                        </FormGroup>
                                   
                                </Col>
                            </Row>
                          
                            
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold">Shipping Address <span className="text-red">&nbsp;*</span></Label><br /><br />
                                        {errors.sAddress && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.sAddress}</p>} 

                                        <Input type="text" name="sAddress" value={values.sAddress} onChange={handleChange} bsSize="sm"></Input>
                                        <FormText><span className="text-primary">Street Address</span></FormText><br />
                                        <Input type="text" className="sAddress2"  value={values.sAddress2} onChange={handleChange} bsSize="sm"></Input>
                                        <FormText><span className="text-primary">Street Address Line 2</span></FormText><br />
                                        <Row>
                                            <Col>
                                                <Input type="text" name="city"  value={values.city} onChange={handleChange} bsSize="sm"></Input>
                                                <FormText><span className="text-primary">City</span></FormText><br />
                                            </Col>
                                            <Col>
                                                <Input type="text" name="state" value={values.state} onChange={handleChange} bsSize="sm"> </Input>
                                                <FormText><span className="text-primary">State / Province</span></FormText>                                                </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Input type="text" name="postal"  value={values.postal} onChange={handleChange} bsSize="sm"></Input>
                                                <FormText><span className="text-primary">Postal / Zip Code</span></FormText><br />
                                            </Col>
                                            <Col>
                                                <Input
                                                    id="exampleSelect"
                                                    name="countrySelect"
                                                    type="select"
                                                    value={values.countrySelect} 
                                                    onChange={handleChange}
                                                    bsSize="sm"
                                                    >
                                                        <option>Please Select</option>
                                                        <option value="Afganistan">Afghanistan</option>
                                                        <option value="Albania">Albania</option>
                                                        <option value="Algeria">Algeria</option>
                                                        <option value="American Samoa">American Samoa</option>
                                                        <option value="Andorra">Andorra</option>
                                                        <option value="Angola">Angola</option>
                                                        <option value="Anguilla">Anguilla</option>
                                                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bolivia">Bolivia</option>
                                                        <option value="Bonaire">Bonaire</option>
                                                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                        <option value="Botswana">Botswana</option>
                                                        <option value="Brazil">Brazil</option>
                                                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                        <option value="Brunei">Brunei</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Canary Islands">Canary Islands</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Central African Republic">Central African Republic</option>
                                                        <option value="Chad">Chad</option>
                                                        <option value="Channel Islands">Channel Islands</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Christmas Island">Christmas Island</option>
                                                        <option value="Cocos Island">Cocos Island</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Cote DIvoire">Cote DIvoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cuba">Cuba</option>
                                                        <option value="Curaco">Curacao</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="East Timor">East Timor</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="El Salvador">El Salvador</option>
                                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                        <option value="Eritrea">Eritrea</option>
                                                        <option value="Estonia">Estonia</option>
                                                        <option value="Ethiopia">Ethiopia</option>
                                                        <option value="Falkland Islands">Falkland Islands</option>
                                                        <option value="Faroe Islands">Faroe Islands</option>
                                                        <option value="Fiji">Fiji</option>
                                                        <option value="Finland">Finland</option>
                                                        <option value="France">France</option>
                                                        <option value="French Guiana">French Guiana</option>
                                                        <option value="French Polynesia">French Polynesia</option>
                                                        <option value="French Southern Ter">French Southern Ter</option>
                                                        <option value="Gabon">Gabon</option>
                                                        <option value="Gambia">Gambia</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Germany">Germany</option>
                                                        <option value="Ghana">Ghana</option>
                                                        <option value="Gibraltar">Gibraltar</option>
                                                        <option value="Great Britain">Great Britain</option>
                                                        <option value="Greece">Greece</option>
                                                        <option value="Greenland">Greenland</option>
                                                        <option value="Grenada">Grenada</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Hawaii">Hawaii</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="India">India</option>
                                                        <option value="Iran">Iran</option>
                                                        <option value="Iraq">Iraq</option>
                                                        <option value="Ireland">Ireland</option>
                                                        <option value="Isle of Man">Isle of Man</option>
                                                        <option value="Israel">Israel</option>
                                                        <option value="Italy">Italy</option>
                                                        <option value="Jamaica">Jamaica</option>
                                                        <option value="Japan">Japan</option>
                                                        <option value="Jordan">Jordan</option>
                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                        <option value="Kenya">Kenya</option>
                                                        <option value="Kiribati">Kiribati</option>
                                                        <option value="Korea North">Korea North</option>
                                                        <option value="Korea Sout">Korea South</option>
                                                        <option value="Kuwait">Kuwait</option>
                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                        <option value="Laos">Laos</option>
                                                        <option value="Latvia">Latvia</option>
                                                        <option value="Lebanon">Lebanon</option>
                                                        <option value="Lesotho">Lesotho</option>
                                                        <option value="Liberia">Liberia</option>
                                                        <option value="Libya">Libya</option>
                                                        <option value="Liechtenstein">Liechtenstein</option>
                                                        <option value="Lithuania">Lithuania</option>
                                                        <option value="Luxembourg">Luxembourg</option>
                                                        <option value="Macau">Macau</option>
                                                        <option value="Macedonia">Macedonia</option>
                                                        <option value="Madagascar">Madagascar</option>
                                                        <option value="Malaysia">Malaysia</option>
                                                        <option value="Malawi">Malawi</option>
                                                        <option value="Maldives">Maldives</option>
                                                        <option value="Mali">Mali</option>
                                                        <option value="Malta">Malta</option>
                                                        <option value="Marshall Islands">Marshall Islands</option>
                                                        <option value="Martinique">Martinique</option>
                                                        <option value="Mauritania">Mauritania</option>
                                                        <option value="Mauritius">Mauritius</option>
                                                        <option value="Mayotte">Mayotte</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Midway Islands">Midway Islands</option>
                                                        <option value="Moldova">Moldova</option>
                                                        <option value="Monaco">Monaco</option>
                                                        <option value="Mongolia">Mongolia</option>
                                                        <option value="Montserrat">Montserrat</option>
                                                        <option value="Morocco">Morocco</option>
                                                        <option value="Mozambique">Mozambique</option>
                                                        <option value="Myanmar">Myanmar</option>
                                                        <option value="Nambia">Nambia</option>
                                                        <option value="Nauru">Nauru</option>
                                                        <option value="Nepal">Nepal</option>
                                                        <option value="Netherland Antilles">Netherland Antilles</option>
                                                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                        <option value="Nevis">Nevis</option>
                                                        <option value="New Caledonia">New Caledonia</option>
                                                        <option value="New Zealand">New Zealand</option>
                                                        <option value="Nicaragua">Nicaragua</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Nigeria">Nigeria</option>
                                                        <option value="Niue">Niue</option>
                                                        <option value="Norfolk Island">Norfolk Island</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau Island">Palau Island</option>
                                                        <option value="Palestine">Palestine</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Phillipines">Philippines</option>
                                                        <option value="Pitcairn Island">Pitcairn Island</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                        <option value="Republic of Serbia">Republic of Serbia</option>
                                                        <option value="Reunion">Reunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russia">Russia</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="St Barthelemy">St Barthelemy</option>
                                                        <option value="St Eustatius">St Eustatius</option>
                                                        <option value="St Helena">St Helena</option>
                                                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                        <option value="St Lucia">St Lucia</option>
                                                        <option value="St Maarten">St Maarten</option>
                                                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                        <option value="Saipan">Saipan</option>
                                                        <option value="Samoa">Samoa</option>
                                                        <option value="Samoa American">Samoa American</option>
                                                        <option value="San Marino">San Marino</option>
                                                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                                        <option value="Senegal">Senegal</option>
                                                        <option value="Seychelles">Seychelles</option>
                                                        <option value="Sierra Leone">Sierra Leone</option>
                                                        <option value="Singapore">Singapore</option>
                                                        <option value="Slovakia">Slovakia</option>
                                                        <option value="Slovenia">Slovenia</option>
                                                        <option value="Solomon Islands">Solomon Islands</option>
                                                        <option value="Somalia">Somalia</option>
                                                        <option value="South Africa">South Africa</option>
                                                        <option value="Spain">Spain</option>
                                                        <option value="Sri Lanka">Sri Lanka</option>
                                                        <option value="Sudan">Sudan</option>
                                                        <option value="Suriname">Suriname</option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Syria">Syria</option>
                                                        <option value="Tahiti">Tahiti</option>
                                                        <option value="Taiwan">Taiwan</option>
                                                        <option value="Tajikistan">Tajikistan</option>
                                                        <option value="Tanzania">Tanzania</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Togo">Togo</option>
                                                        <option value="Tokelau">Tokelau</option>
                                                        <option value="Tonga">Tonga</option>
                                                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                        <option value="Tunisia">Tunisia</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Erimates">United Arab Emirates</option>
                                                        <option value="United States of America">United States of America</option>
                                                        <option value="Uraguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Vanuatu">Vanuatu</option>
                                                        <option value="Vatican City State">Vatican City State</option>
                                                        <option value="Venezuela">Venezuela</option>
                                                        <option value="Vietnam">Vietnam</option>
                                                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                        <option value="Wake Island">Wake Island</option>
                                                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zaire">Zaire</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                </Input>
                                                <FormText><span className="text-primary">Country</span></FormText>
                                            </Col>
                                        </Row>
                                    </FormGroup>                               
                                </Col>
                            </Row>
                            
                            <Row className="mt--2">
                                <Col>
                                  
                                    <FormGroup tag="fieldset">
                                    <legend className="my-3 font-weight-bold">
                                 Type of Hardware Request <span className="text-red">&nbsp;*</span>
                                    </legend>
                                    {errors.hrradio1 && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.hrradio1}</p>} 

                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="hrradio1"
                                        type="radio"
                                        onClick={hideFields}
                                        value={values.hrradio1}
                                        onChange={handleChange}
                                    />
                                    {' '}
                                    <label check className="font-weight-normal" id="smallLabel">
                                      ACAM Kit Shipping Request
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="hrradio1"
                                        type="radio"
                                        onClick={hideFields}
                                        value={values.hrradio1}
                                        onChange={handleChange}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span >ACAM Kit Replacement Request</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="hrradio1"
                                        type="radio"
                                        onClick={hideFields}
                                        value={values.hrradio1}
                                        onChange={handleChange}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span>Replacement Component Request</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="hrradio1"
                                        type="radio"
                                        onClick={showFields}
                                        value={values.hrradio1}
                                        onChange={handleChange}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span>Other</span>
                                    </label>
                                    <Input type="text" 
                                           className="mt-2 ml--4"
                                           placeholder="Please type other option here.."
                                           id="displayFields" />
                                    </FormGroup>
                                   
                                </FormGroup>
                                   
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                 
                                    <FormGroup tag="fieldset">
                                    <legend className="my-3 font-weight-bold">
                                        Reason for Replacement
                                    </legend>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields1}
                                    />
                                    {' '}
                                    <label check className="font-weight-normal" id="smallLabel">
                                      Kit/Component Damaged
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields1}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                     Kit/Component Lost or Stolen
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields1}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    Kit/Component does not function
                                    </label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        id="other"
                                        onClick={showFields1}
                                    />
                                    {' '}
                                    <label check  className="font-weight-normal" id="smallLabel">
                                    <span>Other</span>
                                    </label>
                                    <Input type="text" 
                                           className="mt-2 ml--4"
                                           placeholder="Please type other option here.."
                                           id="displayFields1" />
                                    </FormGroup>
                                </FormGroup>
                                  
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Col>
                                   
                                    <legend className="font-weight-bold">
                                       Component(s) requested:
                                    </legend>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    
                                    >
                                    iPad 11 Pro
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                    Batter Pack/Charger
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                   iPad Charger and Cable
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                    iPad Protective Case
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                   iPad Protective Cloth Sleeve
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                    Aluminum Tripod Mount
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                   Tripod
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={hideFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                    Pelican Protective ACAM Case
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-1">
                                    <Input
                                    id="exampleCheck"
                                    name="check"
                                    type="checkbox"
                                    onClick={showFields2}
                                    />
                                    <Label
                                    check
                                    for="exampleCheck"
                                    className="font-weight-normal"
                                    id="smallLabel"
                                    >
                                    Other
                                    </Label>
                                    <Input type="text" 
                                           className="mt-2 ml--4"
                                           placeholder="Please type other option here.."
                                           id="displayFields2" />
                                  
                                    </FormGroup>
                                   
                                </Col>
                            </Row>
                            <br /> <br />

                            <Row>
                                <Col lg="6">
                                   
                                        <FormGroup>
                                            <Label for="exampleDate" className="font-weight-bold" >
                                            Requested Delivery Date <span className="text-red">&nbsp;*</span>
                                            </Label>
                                            {errors.date && <p className="text-danger"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;{errors.date}</p>} 

                                            <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            value={values.date}
                                            onChange={handleChange}
                                            />
                                            <FormText>
                                                <span className="text-primary">
                                                Please ensure that the requested delivery date is at least 4 business days prior to any scheduled use of the kit or materials and accounts for time for training and use of device.
                                                </span>
                                            </FormText>
                                        </FormGroup>
                                  
                                </Col>
                            </Row>
                          

                            <Row>
                                <Col>
                               
                                <FormGroup tag="fieldset">
                                    <legend className="my-3 font-weight-bold">
                                    Are you the receiving contact for shipping?

                                    </legend>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={hideFields}
                                    />
                                    {' '}
                                    <label check  id="smallLabel">
                                      <span className="font-weight-normal">Yes</span>
                                    </label>
                                    </FormGroup>
                                    <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onClick={showFields}
                                    />
                                    {' '}
                                    <label check   id="smallLabel">
                                    <span className="font-weight-normal">No, I will enter details for a different receiving contact</span>
                                    </label>
                                    </FormGroup>
                                </FormGroup>
                               
                            
                                </Col>
                            </Row>
                            
                            <Row className="mt-1" id="displayFields">
                                <Row>
                                <Col >
                                    <Label
                                        className="font-weight-bold"
                                        htmlFor="input-first-name"
                                    >
                                       Receiving Contact Name<span className="text-red">&nbsp;*</span>
                                    </Label>
                                <Row>
                                <Col lg="6">
                                    
                                    <FormGroup>
                                        <Input    
                                            //   defaultValue="Lucky"
                                            id="input-first-name"
                                            placeholder="First name"
                                            type="text"
                                        />
                                    <FormText><span className="text-primary">First Name</span></FormText>
                                    </FormGroup>
                                    </Col>
                                    <Col>
                                    <FormGroup>
                                        <Input 
                                            //   defaultValue="Jesse"
                                            id="input-last-name"
                                            placeholder="Last name"
                                            type="text"
                                        />
                                        <FormText><span className="text-primary">First Name</span></FormText>
                                    </FormGroup>
                                    </Col>
                                    </Row>
                                </Col>
                                </Row>
                                <Row>
                                <Col lg="6">
                                   
                                        <FormGroup>
                                            <Label className="font-weight-bold">Receiving Contact Phone Number</Label>
                                            <Input type="text" name="contact-phone" placeholder="(000) 000-0000"/>
                                        </FormGroup>
                                    
                                </Col>
                                <Col lg="6">
                                   
                                        <FormGroup>
                                            <Label className="font-weight-bold">Receiving Contact Email</Label>
                                            <Input type="text" name="contact-phone" />
                                            <FormText><span className="text-primary">example@example.com</span></FormText>
                                        </FormGroup>
                                  
                                </Col>
                                </Row>
                            </Row>
                            </Collapse>
                            </div>
                            {/* <Row className="mt-1"  id="displayFields">
                                <Col>
                                   
                                        <FormGroup>
                                            <Label className="font-weight-bold">Receiving Contact Phone Number</Label>
                                            <Input type="text" name="contact-phone" placeholder="(000) 000-0000"/>
                                        </FormGroup>
                                    
                                </Col>
                                <Col>
                                   
                                        <FormGroup>
                                            <Label className="font-weight-bold">Receiving Contact Email</Label>
                                            <Input type="text" name="contact-phone" />
                                            <FormText><span className="text-primary">example@example.com</span></FormText>
                                        </FormGroup>
                                  
                                </Col>
                            </Row> */}
             
                            <div >
                            <Row className="p-3 h-100 rounded">
                                <Col className="bg-default text-white pt-2 rounded">
                                <Label color="primary" onClick={toggleAdditional} >
                                <i class="fa-solid fa-circle-chevron-down fa-lg"></i>&nbsp; Additional Information
                            </Label>
                                </Col>                           
                            </Row>
                            <Collapse isOpen={additional} >
                            <Row>
                                <Col>
                                <FormGroup>
                                    <Label for="exampleText" className="font-weight-bold">
                                    Notes or additional information related to request:
                                    </Label>
                                    <Input
                                    id="exampleText"
                                    name="text"
                                    type="textarea"
                                    rows="5"
                                    />
                                <FormText><span className="text-primary">Please provide any relevant details</span></FormText>
                                </FormGroup>
                                </Col>
                            </Row>
                            </Collapse>
                            </div>

                            {/* <Row className="mt-4 bg-gray align-items-center  rounded cursor-pointer" onClick={buttonsAppear}>
                                <Col lg="11" className="pt-3 pb-2 pl-4">
                                    <h2 className="text-white">Please check the above information, then click here.</h2>
                                </Col>
                                <Col className="">
                                <i class="fa-solid fa-circle-arrow-down fa-xl text-white"></i>
                                </Col>
                            </Row> */}
                            

                            
                            <div className="text-center p-1" >
                               
                                    <Link to="/admin/hardwareRequest">
                                        <Button
                                        color="danger"
                                        // size="md"
                                        >
                                            Back
                                        </Button>
                                    </Link>
                                    &nbsp;&nbsp;
                              
                                    {/* <Link to="/admin/hardwareRequest"> */}
                                        <Button
                                        color="primary"
                                        // size="md"
                                      
                                        >
                                            Submit
                                        </Button>
                                    {/* </Link> */}
                                
                           </div>
                            
                            </Form>
                        </CardBody>     
                    </Card>
                
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default HardwareRequest;